
input {border:0;outline:0;}
input:focus {outline:none!important;}
textarea {border:0;outline:0;resize: none; font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
textarea:focus {outline:none!important;}
p {
  display: block;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
/* .aside {
  flex-shrink: 0;
  width: 263px;
  max-width: 263px;
  z-index: 99999999;
  background-color: #113946;
  color: #fff; 
} */
/* 
/* .main{
  padding: 21px 44px;
  background: #f4f9fc;
  width: 100%;
  min-height: calc(100vh - 40px);
  position: relative;
}
.header{
  display: flex;
  position: fixed;
  width: 100%;
  z-index: 99;
  top: 0;
  left: 263px;
  display: flex;
 
} */ 
/* .main{
  padding: 21px 44px;
  background: #f4f9fc;
  width: 100%;
  min-height: calc(100vh - 40px);
  position: relative;
} */

.center_container {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 720px;
}
.test_container{
  height: fit-content;
  min-height: 760px;
}
.center_icon_sber {
  margin-left: 310px;
  margin-bottom: 24px;
}
.sber_online{
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 25px;
  color: #000000;
  text-align: center;
  margin-bottom: 48px;
  
}
.text_area{
  width: 100%;
  height: 260px;
  background: #FFFFFF;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  padding: 32px;
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 25px;
  color: #000000;

  
}
.text_area::placeholder{
  opacity: 0.5;
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 25px;
  color: #000000;
}
.search_container{
  display: flex;
  margin-bottom: 36px;
}
.search{
  background: #E3E7EE;
  border-radius: 12px;
  padding: 20px 16px;
  display: flex;
  width: calc(70% - 32px);
  margin-right: 28px;

}
.account{
  background: #FFFFFF;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  width: calc(30% - 60px);
  padding: 12px 16px;
}
/* .logo{
  margin-left: 35px;
  margin-top: 26px;
  margin-bottom: 36px;
} */
.avatar{
  width: 40px;
  height: 40px;
  margin-right: 16px;
}
.acc_name{
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin-bottom: 6px;
}
.menu_item{
  display: flex;
  margin-top: 10px;
  cursor: pointer;
  transition: all 0.3s;
  margin-right: 22px;
  padding-left: 37px;
}
.menu_title_active{
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #8BEAB6;
  margin: 19px 12px 19px 12px;
}
.menu_title{
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(255, 255, 255, 0.7);
  margin: 19px 12px 19px 12px;
}
.menu_icon{
 
}
.hover{
  transition: all 0.3s;
  cursor: pointer;
}
.menu_item_picked{
  background: rgba(255, 255, 255, 0.1);
  border-radius: 0px 12px 12px 0px;
  padding-left: 33px;
  border-left: 4px solid #8BEAB6;
}
.main_title{
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 33px;
  color: #000000;
  margin-bottom: 16px;
}
.filter_container{
  height: 24px;
  margin-top: 10px;
}
.just_content{
  display: flex;
  justify-content: space-between;
  width: auto;
}
.icon_filters{
  margin-right: 16px;
}
.filter_text{
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  color: #000000;
  opacity: 0.8;
  margin-right: 3px;
  margin-top: 1px;
}
.reviews_page{
  display: block;
  width: 100%;
}
.reviews_flex{
  display: flex;
  width: 100%;
  margin-top: 24px;
}
.reviews_list_container{
  width: calc(70% - 20px);
  position: relative;
  min-height: calc(100vh - 200px);
  border-right: 1px solid #D1D8E4;
  padding-right: 20px;
}
.full_width{
  width: 100%;
}
.review_item{
  padding: 24px 16px 16px 16px;
  border-bottom: 1px solid #D1D8E4;
}
.star_icon{
  height: 16px;
  margin-left: 2px;
  margin-right: 16px;
}
.light_gray{
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #113946;
  opacity: 0.5;
  white-space: nowrap;
  height: 14px;
}
.icon_add{
  margin-top: -8px;
}
.checked_icon{
  margin-left: 12px;
  margin-top: 6px;
}
.more_icon{
  padding-left: 16px;
}
.date{
  margin-right: 16px;
  margin-top: 2px;
}
.filter_categ{
  padding: 18px;
  position: relative;
}
.review_text{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  opacity: 0.8;
  margin-top: 16px;
  margin-bottom: 16px;
}
.flex{
  display: flex;
}
.reviewer_info{
  margin-left: 16px;
}
.reviewer_name{
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 6px;
  color: #000000;
}
.categories_container{
  display: flex;
  flex-wrap: wrap;
  
}
.category_cont{
  border-radius: 110px;
  padding: 8px 16px;
  height: fit-content;
  width: fit-content;
  display: flex;
  margin-right: 8px;
  margin-bottom: 8px;
}
.category_gray{
  background: #E3F0FF;
  
}
.category_green{
  background: #FFF0DE;
}
.category_title{
  font-size: 14px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.8);
  margin-left: 12px;
}
.right_filter{
  background: #FFFFFF;
 
  width: calc(30% - 28px);
  margin-left: 20px;
  margin-top: 0px;
  height: fit-content;
  min-width: 305px;
}
.right_filter_title{
  margin: 24px 16px 16px 16px;
  font-size: 21px;
  line-height: 20px;
  color: #000000;
}
.line{
  height : 1px;
  background :#D1D8E4;
  width: 100%;
}
.reset_filters{
  font-size: 16px;
line-height: 28px;
color: #1696EC;
}
.team{
  font-size: 16px;
  line-height: 20px;
  color: #113946;
  margin-bottom: 4px;
}

.tag_desription{
  margin-top: 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  opacity: 0.8;
  width: 100%;
}
.counter{
  background: rgba(0, 0, 0, 0.7);
  padding: 1px 5px 1px 5px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #FFFFFF;
  border-radius: 200px;
  height: fit-content;
  width: fit-content;
  margin-top: 3px;
  margin-left: 4px;
}
.filter_pad{
  padding: 12px 18px;
  position: relative;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.filter_pad:hover{
  background: #F1F7FF;
}
.filter_pad:focus{
  background: #F1F7FF;
}
/* .filter_pad:hover p{
  color: white;
} */
.relative{
  position: relative;
}
.mobile_filters{
  display: flex;
  padding: 16px;
  justify-content: space-between;
}
.animation_list-enter {
  opacity: 0.0;
}
.animation_list-appear {
  opacity: 0.01;
}

.animation_list-appear.animation_list-appear-active {
  opacity: 1;
  transition: opacity .5s ease-in;
}
.element {
  animation: opacity_anim 0.3s ease-in;
}
.search input{
  border: none;
  background: #E3E7EE;
  color: #113946;
  opacity: 0.5;
  margin-left: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  width: 100%;
  border-width:0px;
}
.search input:focus, input:active{
  border: none;
  border-width:0px;
}
.text_btn{
  background: #3BB099;
  border-radius: 12px;
  padding: 15px 33px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  position: absolute;
  bottom: 28px;
  margin-left: 32px;
  color: #FFFFFF;
}
.write_review_btn{
  background: #079D4D;
  border-radius: 12px;
  padding: 15px 33px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-top: 48px;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
@keyframes opacity_anim {
  0% {
    opacity: 0.01;
  }
  100% {
    opacity: 1.0;
  }
}
/* .lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 6px;
  border: 3px solid #3BB099;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #3BB099 transparent transparent transparent;

}
.choose-app{
  margin-bottom: 0px;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
} */
.loader_text{
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 20px;
  text-align: center;
  color: #113946;
  width: 260px;
  margin-top: 60px;
  margin-left: -100px;
}
.loader-earn-container {
  margin: auto;
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  top: 0;
  width: 60px;
  height: 60px;
  bottom: 0;
}
@media (hover: hover) {
  .hover:hover{
      opacity: 0.7;
  }
  .menu_item:hover{
    background: rgba(255, 255, 255, 0.1);
    border-radius: 0px 12px 12px 0px;
  }
}
.center_bookmark{
  transform: translate(calc(-50% + 121px), -50%);
}
.m_right_8{
  margin-right: 8px;
}
.m_left_8{
  margin-left: 8px;
}
.w_100{
  width: 100%;
}
.tag_info{
  position: absolute;
  top: 0;
  left: 0;
  height: fit-content;
  min-height: 100vh;
  width: -webkit-fill-available;
  background: white;
  z-index: 99;
  padding: 36px 24px;
}
.add_tag_info_title{
  font-size: 28px;
  line-height: 28px;
  color: #000000;
  font-family: RobotoSlab-Bold;
  margin-bottom: 30px;
}
.review_text_container{
  width: 100%;
  display: flex;
}
.add_info_tag_line{
  height: 1px;
  width: 100%;
  background:#E4ECEF;
  margin-top: 24px;
  margin-bottom: 24px;
}
.info_tag_text_cont{
  width: calc(50% - 32px);
  background: rgba(244, 249, 252, 0.5);
  border: 1px solid #E7EEF2;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px;
  height: 141px;
  margin-right: 16px;
  overflow: scroll;
}
.info_tag_title{
  font-family: OpenSans-Bold;
  margin-bottom: 10px;
}
.info_tag_text{
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  opacity: 0.8;
}
.info_tag_text::selection{
  background: rgba(255,194,140, 0.5);
}
.info_tag_desc_cont{
  width: 50%;
  margin-left: 16px;
  margin-top: 16px;
}
.selected_text_cont{
  background: #112A3D;
  border-radius: 12px;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
  font-family: OpenSans-SemiBold;
  position: absolute;
  padding: 14px 24px;
  display: flex;
  justify-content: space-between;
  width: 268px;
  z-index: 9;

}

.square_selected{
  background: #112A3D;
  width: 17px;
  height: 17px;
  margin: 0;
  position: absolute;
  /* left: calc(50% - 8.5px); */
  top: 0;
  transform: translateY(-50%) rotate(45deg);
}
.tooltip_bg_selection{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.selection_text{
  z-index: 99;
}
.enter_tag_name{
  border-radius: 4px;
  padding: 14px 16px;
  font-size: 14px;
  line-height: 16px;
  color: #112A3D;
  width: 100%;
}
.enter_tag_name::placeholder{
  color: #112A3D;
  opacity: 0.5;
}
.flex_input_cont{
  position: relative;
  width: calc(50% - 32px);
  margin-right: 16px;
  display: flex;
  border: 1px solid #C9D5DA;
  box-sizing: border-box;
  border-radius: 4px;
  height: 48px;

}
.line_tag_name{
  height: calc(100% - 16px);
  width: 1px;
  background: #C9D5DA;
  margin-top: 8px;
  margin-bottom: 8px;
}
.drop_arrow_tagname{
  margin: 12px;

}
.top_0_m{
  margin-top: 4px;
}
.tags_name_list{
      width: 100%;
      position: absolute;
    height: fit-content;
    background: #FFFFFF;
    border: 1px solid #D6DBE3;
    box-sizing: border-box;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    top: 15px;
    margin-top: 32px;
    display: block;
    max-height: 296px;
    overflow: scroll;
    transition: all 0.3s ease-in;
    opacity: 0; 
    pointer-events: none;
    z-index: 999;
}
.redactor_tag{
  color: #1696EC;
}
.keywords_input_width{
  transition: opacity 0.3s ease-in-out;
  width: calc(50% - 16px);
}
.keywords_input_width_left{
  margin-right: 16px;
}
.keywords_input_width_right{
  margin-left: 16px;
  width: 50%;
}
.update_tag_btn{
  background: #F6DD00;
  opacity: 0.2;
  box-shadow: 0px 4px 15px rgba(246, 221, 0, 0.4);
  border-radius: 4px;
  color: #000000;
  font-size: 14px;
  font-family: OpenSans-Semibold;
  padding: 18px;
  text-align: center;
  height: fit-content;
  width: 217px;
  pointer-events: none;

}
.update_tag_btn_active{
  opacity: 1;
  pointer-events: all;
}
.mobile_keywords_title_container{
  display: none;
  
}
.mobile_floating_filter{
  display: none;
}
.mobile_filter_cont{
  display: none;
}
.mobile_filter_bg{
  display: none;
}
@media (max-width: 980px) {
  .reviews_flex{
    display: block;
  }
  .mobile_filter_cont{
    display: block;
    width: 305px;
    right: -305px;
    height: 100%;
    top : 0;
    bottom: 0;
    position: fixed;
    z-index: 10000;
    background: white;
    transition: .4s right;
    pointer-events: none;
  }
  .mobile_filter_cont_active{
    right: 0px;
    pointer-events: all;
  }
  .mobile_filter_bg{
    pointer-events: none;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    background: rgba(17, 42, 61, 0.85);
    opacity: 0;
    transition: all 0.4s ease-in-out;
  }
  .mobile_filter_bg_active{
    pointer-events: all;
    opacity: 1;
  }
  .mobile_floating_filter{
    display: block;
    position: fixed;
    background: #1696EC;
    border-radius: 28px;
    height: 56px;
    width: 56px;
    bottom: 18px;
    right: 18px;
    filter: drop-shadow(0px 9px 25px rgba(35, 85, 119, 0.2));
    transition: all 0.3s ease-in-out;
    opacity: 1;
    z-index: 9999999999999;
  }
 
  .mobile_floating_icon{
    margin: 18px 0px 0px 17px;
  }
  .mobile_keywords_title_container{
    display: block;
  }
  .mobile_keywords_title{
    font-size: 14px;
    line-height: 28px;
    color: #112A3D;
    cursor: pointer;
    width: 147px;
  }
  .mobile_keywords_title_active{
    color: #1696EC;
  }
  .mobile_keywords_line{
    height: 3px;
    width: 24px;
    margin-left: 0px;
    transition: all 0.3s ease-in-out;
    background: #1696EC;
    margin-bottom: 16px;
    margin-top: 4px;
  }
  .mobile_keywords_line_ignore{
    margin-left: 147px;
  }
  .reviews_list_container{
    width: 100%;
    border-right : 0px;
    padding-right: 0px;
  }
  .add_tag_info_title{
    font-size: 21px;
    margin-bottom: 16px;
  }
  .info_tag_text_cont{
    width: 100%;
  }
  .review_text_container{
    display: block;
  }
  .info_tag_desc_cont{
    width: 100%;
    margin-left : 0px;
  }
  .flex_input_cont{
    width: 100%;
    margin-bottom: 8px;
  }
  .new_tag_container{
    width: 100%;
    display: block;
  }
  .keywords_input_width{
    width: 100%;
  }
  .keywords_input_width_left{
    margin-left: 0px;
    margin-right: 0px;
  }
  .keywords_input_width_right{
    margin-right: 0px;
    margin-left: 0px;
  }

  .tag_info{
    overflow: hidden;
  }
  .keywords_title{
    display: none;
  }
  .right_filter{
    margin-left: 0px;
  }
}