.rdrCalendarWrapper {
    color: #000000;
    font-size: 12px;
    position: absolute;
    left: 0;
    top: 60px;
    background: #FFFFFF;
    border: 1px solid #E4ECEF;
    box-sizing: border-box;
    padding: 0px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    z-index: 99;
}
@media (max-width: 768px) {
    .rdrCalendarWrapper{
        left: -14px;
        top: 30px;
    }
  }