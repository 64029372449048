.admin_app_item{
    border: 1px solid #e7eff2;
    width: calc(100% / 5 - 28px);
    margin-right: 24px;
    min-width: 200px;
    font-family: OpenSans-Regular;
    margin-bottom: 24px;
    border-radius: 4px;
    padding: 12px;
}
.admin_item_bold{
    font-family: OpenSans-Bold;

}
.admin_payouts_title{
    font-family: OpenSans-Bold;
    margin-bottom: 8px;
}
.admin_user_item{
    font-family: OpenSans-Regular;
    margin: 12px 12px 0 0;
    padding: 12px;
    border-radius: 4px;
    border: 1px solid #e7eff2;
}
.admin_apps_header{
    display: flex;
    justify-content: center;
    margin: 20px;
}
.admin_price_ads{
    border: 1px solid #e7eff2;
    font-family: OpenSans-Regular;
    margin: 0 8px 0 8px;
    border-radius: 4px;
    padding: 6px;
    width: 60px;
}
.admin_more_icons{
    display: flex;
    justify-content: space-between;
}
.admin_more_icon{
    width: 24px;
    height: 24px;
    margin-left: auto;
}
.admin_period_picker{
    border: 1px solid #e7eff2;
    padding: 12px;
    margin-bottom: 16px;
    width: fit-content;
}
.admin_url{
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: OpenSans-Regular;
    /* font-family: OpenSans-Semibold; */
    white-space: nowrap;
    max-width: 90%;
}
.admin_download_txt{
    margin-bottom: 12px;
}