.transformable-profiles {
    -webkit-transition: height 100ms linear;
    -moz-transition: height 100ms linear;
    -o-transition: height 100ms linear;
    -ms-transition: height 100ms linear;
    transition: height 100ms linear;
    transition: 0.1s linear all;
}
.line_white_bg{
        height: 100%;
        position: absolute;
        width: 100%;
        bottom: 0;
        top: 0;
        right: 0;
        width: 22px;
        background: white;
        border-radius: 4px;
}
.line_white_bg_left{
  height: 100%;
  position: absolute;
  width: 100%;
  bottom: 0;
  top: 0;
  left: 0;
  width: 22px;
  background: white;
  border-radius: 4px;
}
.white_bg_tool{
  background: white;
}
.tooltip_plashka {
    opacity: 1;
    height: -moz-fit-content;
    /* height: 300px; */
    display: block;
    position: absolute;
    left: -276px;
    width: 256px;
    top: 110px;
    border-width: 1px;
    border-style: solid;
    border-color: white;
    background: #FFFFFF;
    border: 1px solid #E4ECEF;
    box-sizing: border-box;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    z-index: 99;
  
}
.tooltip_plashka_add_request {
  left: 33.3%;
}
@media (max-width: 1400px) {
  .tooltip_plashka_add_request {
    left: 360px;
  }
}
@media (max-width: 1268px) {
  .tooltip_plashka_add_request {
    left: 360px;
  }
}
@media (max-width: 768px) {
  .tooltip_plashka{
    left: auto;
  }
  .tooltip_plashka_add_request {
    left: 0;
  }
}
.red_container{
  background: #CB413E;
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: -24px;
  padding: 12px 24px 18px 24px;
  cursor: pointer;
  text-align: left;
  position: absolute;
  width: 206px;
  margin-top: 12px;
  border-radius: 0px 0px 4px 4px;
}

.error_text_tooltip{
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
}
.close_icon{
    cursor : pointer;
    height : 25px;
    width : 25px;
    margin-right : 8px;
    margin-top : 8px;
    right : 0;
    position : absolute
}
  .tooltip_arrow {
    position: absolute;
    width: 16px;
    height: 16px;
    margin-top: -8px;
    transform: rotate(45deg);
    right: -8px;
    bottom: 70px;
    /* margin-left: 50%; */
    border-width: 1px;
    border-style: solid;
    border-color: white;
    border-top: 1px;
    border-left: 1px;
    background: white;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
    background-color: white;
  }
  .tooltip_add_balance{
      display: block;
      position: absolute;
  }
  .padding_16{
      padding: 16px;
  }
  .up_balance_title{
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    color: #000000;
  }
  .input_up_balance{
        border: 1px solid #E4ECEF;
        border-radius: 4px;
        padding: 10px 8px 10px 8px;
        font-size: 14px;
        line-height: 19px;
        font-family: OpenSans-Regular;
        margin-top: 8px;
        margin-bottom: 8px;
        width: -webkit-fill-available;
  }
  .price_container{
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }
  .add_reaquest_dialog_title{
    font-family: OpenSans-Bold;
    font-size: 14px;
    line-height: 19px;
    color: #112A3D;
    margin-bottom: 8px;
  }
  .bold{
    font-family: OpenSans-Bold;
  }
  .mt16{
    margin-top: 16px;
    margin-bottom: 8px;
  }
  .input_up_balance:focus{
    border: 2px solid #96C6F0;
    padding: 9px 7px;
  }
  .flex_content{
      display: flex;
      justify-content: space-between;
  }
  .price_one_install{
    font-size: 12px;
    line-height: 16px;
    /* or 133% */
    
    color: #000000;
    margin-bottom: 28px;
    opacity: 0.5;
  }
  .requests_dialog_cont{
    display: block;
    max-height: 320px;
    overflow: scroll;
  }
  .opa_05{
    opacity: 0.5;
    font-size: 12px;
    min-height: 16px;
  }
  .request_desc{
    font-size: 10px;
    line-height: 20px;
    color: #000000;
    opacity: 0.5;
  }
  .line_justify{
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    margin-bottom: 8px;
    min-height: 24px;
  }
  .search_pos_dialog{
    font-size: 12px;
    line-height: 16px;
    color: #112A3D;
  }
  .add_btn{
    background: #1696EC;
    border-radius: 4px;
    color: white;
    padding: 10px;
    text-align: center;
    margin-bottom: 18px;
    cursor: pointer;
    margin-top: 24px;
  }
  .padding_24{
    padding: 24px;
  }
  .cancel_btn{
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    cursor: pointer;
    margin-bottom: 8px;
    /* lk_blue */
    color: #1696EC;
  }
  .cancel_btn:hover{
      opacity: 0.7;
  }
  .add_btn:hover{
      opacity: 0.7;
  }
  .tooltip_bg_up_balance{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
  }
  .univ_up_balance{
    width: 256px;
      left: 0;
      top: -200px;
  }
  .line_white_bg_bot{
    height: 22px;
    position: absolute;
    width: 100%;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    background: white;
    border-radius: 4px;
  }
  .add_tags_info{
    width: 256px;
    left: -126px;
    top: 40px;
  }
  .line_white_bg_top{
    height: 22px;
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    background: white;
    border-radius: 4px;
  }
  .add_tags_text{
    font-size: 12px;
line-height: 16px;

/* lk_left_menu */
color: #2F3D48;
opacity: 0.8;
  }

  .tooltip_tag{
    width: 196px;
    margin : 12px 0px 24px 0px;
  }
  .tooltip_tag_text{
    margin-right: 4px;
  }