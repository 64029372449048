@font-face {
  font-family: OpenSans-Bold;
  /* Имя шрифта */
  src: url(./fonts/NotoSans-Bold.ttf);
  /* Путь к файлу со шрифтом */
}

@font-face {
  font-family: OpenSans-Regular;
  /* Имя шрифта */
  src: url(./fonts/NotoSans-Regular.ttf);
  /* Путь к файлу со шрифтом */
}

@font-face {
  font-family: OpenSans-Semibold;
  /* Имя шрифта */
  src: url(./fonts/NotoSans-SemiBold.ttf);
  /* Путь к файлу со шрифтом */
}

@font-face {
  font-family: OpenSans-Light;
  /* Имя шрифта */
  src: url(./fonts/NotoSans-Light.ttf);
  /* Путь к файлу со шрифтом */
}

@font-face {
  font-family: RobotoSlab-Bold;
  /* Имя шрифта */
  src: url(./fonts/RobotoSlab-Bold.ttf);
  /* Путь к файлу со шрифтом */
}

body {
  outline: 0;
  background: #E7F6FF;
  font-family: OpenSans-Regular;
  outline: none;
  margin: 0px;
  width: 100%;
  /* color: #333; */

}

button {

  outline: 0;
}

textarea {
  resize: none;
}

p {
  outline: 0;
  outline: none;
  margin: 0px;
}

div:focus {
  outline: none;
}

div:active {
  outline: none;
}

p:focus {
  outline: 0;
}

img:focus {
  outline: 0;
}

i:focus {
  outline: 0;
}

input:focus {
  outline: none;
}

input:active {
  outline: none;
}

a {
  color: #1696EC;
  text-decoration: none;
}

b {
  font-family: OpenSans-Bold;
}

input,
textarea,
button,
select,
p,
img,
div,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

#collapsed-row {
  visibility: collapse;
  display: none;
}

:root {
  --lk-left-menu: #112a3d;
  --gray: #6b7379;
  --white: #fff;
  --lk-blue-hover: #0077c7;
  --lk-blue: #1696ec;
  --lk-form-lines: #e4ecef;
  --light-blue: #e7f6ff;
  --lk-background: #f4f9fc;
  --disabled-input: rgba(228, 236, 239, 0.4);
  --lk-green: #8beab6;
  --yellow-bg: #fff3c9;
  --lk-yellow: #ffe141;
  --lk-yellow-hover: #f3d11f;
  --lk-orange: #ffc28c;
  --red-dark: #ff6262;
  --input-stroke: #c9d5da;
  --input-stroke-focus: #96c6f0;
  --input-stroke-focus-30: rgba(150, 198, 240, 0.3);
  --red-light: #ff9696;
  --black: #000;
  --gray-icons: #b0bec6;
  --black: #000;
}

#outer {
  height: 400px;
  position: relative;
}

.progress-style {
  height: 400px;
  align-self: center;
  align-items: center;
}

.reviewPlashka {
  left: 290px;
  position: fixed;
  top: 0;
  z-index: 99999;
  background: white;
  box-shadow: rgb(0 0 0 / 20%) -4px 6px 8px 0px;
  display: flex;
  width: calc(100vw - 290px);
}

.review-texts {
  display: block;
  padding-top: 32px;
  padding-bottom: 32px;
}

.hover {
  transition: opacity 300ms;
  cursor: pointer;
}

@media (hover: hover) {
  .hover:hover {
    opacity: 0.7;
  }
}

#inner {
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.display_block {
  display: block;
}

.link_to_universal {
  background: #FFFFFF;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  padding: 16px 24px 16px 24px;
  margin: 16px 16px -4px 16px;
}

.img_univ_link {
  margin-right: 8px;
}

.link_text {
  color: #1696EC;
  opacity: 0.8;
  font-size: 14px;
  line-height: 19px;
}

@media only screen and (min-width: 769px) {
  .navbar-fixed-top {
    top: 0;
    border: 0 solid red !important;
    margin-left: 300px;
    background: #E7F6FF;
    /*border-width: 0 0 1px;*/

  }

  .row-navbar-top {
    visibility: collapse;
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .navbar-fixed-top {
    top: 0;
    border: 0 solid red !important;
    display: none;

    background: #E7F6FF;
    /*border-width: 0 0 1px;*/
  }

  .row-navbar-top {
    visibility: visible;
    display: block;

  }

}

.side-menu-visible {
  position: fixed;


  background: #112A3D;
  color: #fff;
  width: 290px;
  padding-top: 40px;


  float: left;
  display: block;
  visibility: visible;
  left: 0;
  min-height: 100%;

}

.user-profile {
  margin-top: -2px;
  float: right;
  margin-bottom: 15px;


}

.back-vis {
  -webkit-backface-visibility: hidden;
}

.user-profile img {
  max-width: 50px;
  max-height: 50px;
}

.user-profile a:active {
  color: #E7F6FF;
}

.right-algn {
  float: right;
  margin-top: -30px;
  margin-right: 30px;
}

.text-right-nav-img {

  margin-top: 0px;
  margin-right: 10px;
  margin-bottom: 0px;
  font-family: OpenSans-Bold;
}

.text-money-paddingleft {
  font-weight: bold;
  margin-left: 5px;
}

.nav-top-row {
  margin-top: 20px;
}

.close-review {
  position: absolute;
  cursor: pointer;
  width: 24px;
  right: 0;
  margin: 16px;

}

.header-top-text {
  margin-bottom: 0;
  margin-top: 20px;
}

.top_30 {
  margin-top: 30px;
}

.bot_30 {
  margin-bottom: 30px;
}

.bot_10 {
  margin-bottom: 10px;
}

.bot_16 {
  margin-bottom: 16px;
}

.height_animation {
  -webkit-transition: max-height 0.2s;
  -moz-transition: max-height 0.2s;
  -ms-transition: max-height 0.2s;
  -o-transition: max-height 0.2s;
  transition: max-height 0.2s;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -ms-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;
  max-height: 0;
  opacity: 0.0;
}

.exit-item {
  top: 0;
  right: 0;
  text-align: right;
  /* width: 80px; */
  margin-right: 32px;
  position: fixed;
  float: right;
  display: none;
  left: unset;
  margin-top: 60px;
  /* padding: 8px; */
  min-width: 80px;
}

.nav-bot-row {
  margin-top: 10px;
}

/*.navbar-default .navbar-nav #user-profile {
height: 50px;
padding-top: 15px;
padding-left: 58px;
}

.navbar-default .navbar-nav #user-profile img {
height: 45px;
width: 45px;
position: absolute;
top: 2px;
left: 8px;
padding: 1px;
}*/
.invisible-row {
  visibility: collapse;

  display: none;
}

.text-rub {
  font-size: 12px;
  margin-left: 10px;
  margin-top: 7px;
  color: #7f7f7f;
}

.text_progress_order {
  font-size: 14px;
  opacity: 0.7;

}

.width_100 {
  width: 100%;
}

.order_row {
  display: flex;
}

.progress_top {
  opacity: 1;
  font-family: OpenSans-Bold;
}

.mobile_cost {
  display: none;
}

.land_cost {
  display: flex;
}

.text-cost-left {
  margin-top: 0px;
  margin-left: 24px;
  font-size: 15px;
  margin-right: 8px;
}

.top_4 {
  margin-top: 4px;
}

.max_installs_container {
  display: block;
  margin-top: 32px;
}

.text-cost-right {
  margin-top: 0px;
  font-size: 15px;
  font-weight: bold;
  font-family: OpenSans-Bold;
}

.orders_container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}

.order_price_bold {
  font-family: OpenSans-Bold;
}

.order_price_android {
  margin-left: 4px;
}

.order_price_ios {
  margin-left: 4px;
}

.order_container {
  border-right: 2px solid #e7eff2;
  border-top: 2px solid #e7eff2;
  border-left: 2px solid #e7eff2;
  width: calc(100% / 5 - 28px);
  margin-right: 24px;
  min-width: 200px;
  font-family: OpenSans-Regular;
  margin-bottom: 64px;
  border-radius: 4px;
}

.create_order_prices {
  border-radius: 8px;
  padding: 12px 16px;
  width: fit-content;
  background: #f4f9fc;
  font-family: var(--second-family);
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: var(--black);
  padding: 12px 18px;
  display: flex;
  height: fit-content;
}

.create_order_j_emoji {
  margin: 0;
}

.create_order_just {
  display: flex;
  margin-bottom: 0;
  justify-content: space-between;
}

.create_order_j_flex {
  display: flex;
}

.top_order {
  display: flex;
  justify-content: space-between;
}

.icon_container {
  width: 24px;
}

.order_icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.order_icon:hover {
  opacity: 0.7;
}

.order_app_icon {
  width: 64px;
  height: 64px;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 12px;
  cursor: pointer;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
}

.order_type_icon {
  position: absolute;
  right: -12px;
  bottom: -12px;
}

.order_icon_container {
  position: relative;
  margin-top: -44px;
}

.order__info_icon_container {
  position: relative;
  width: fit-content;
  height: 64px;
}

.info_title {
  font-size: 32px;
  line-height: 36px;
  align-items: center;
  color: #000000;
  font-weight: 900;
  font-family: RobotoSlab-Bold;
}

.info_title_container {
  display: block;
  margin-left: 32px;
}

.order_name {
  margin-top: 12px;
  font-size: 14px;
  line-height: 28px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: OpenSans-Semibold;
  white-space: nowrap;
  text-align: center;
  max-width: 100%;
  cursor: pointer;
  min-height: 28px;
}

.android_ml {
  margin-bottom: 0px;
}

.ios_ml {
  margin-top: 0px;
}

.search_icon {
  margin-top: 8px;
  margin-right: 8px;
}

.tasks_icon {
  margin-top: 12px;
  margin-right: 8px;
  margin-left: 8px;
  display: none;
}

.order_prop_text {
  margin-left: 24px;
  margin-bottom: 2px;
}

.order_prop_max {
  margin-bottom: 2px;
}

.bot_8 {
  margin-bottom: 8px;
}

.order_info {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: -webkit-fill-available;
  background: white;
  z-index: 99;
  padding: 28px 24px 28px 24px;
  /* overflow : scroll; */
}

.close_order_icon {
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
}

.close_order_icon:hover {
  opacity: 0.7;
}

.order_search_text {
  font-size: 12px;
  line-height: 16px;
  margin-top: 8px;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: OpenSans-Regular;
  /* font-family: OpenSans-Semibold; */
  white-space: nowrap;
  max-width: 90%;

}

.top_12 {
  margin-top: 12px;
}

.order_info_status {
  background: #FF6262;
  border-radius: 30px;
  padding: 6px 12px 6px 12px;
  width: fit-content;
  color: black;
  white-space: nowrap;
  font-size: 12px;
  height: fit-content;
}

.order_info_status_container {
  margin-top: 12px;
  display: flex;
}

.finished_task_status {
  font-size: 14px;
  line-height: 20px;
  margin-top: 5px;
  /* identical to box height, or 143% */

  color: #000000;

  opacity: 0.5;
  margin-left: 32px;
}

.info_container {
  display: flex;

}

.search_words_container {
  background: rgba(244, 249, 252, 0.5);
  border: 1px solid #E7EEF2;
  box-sizing: border-box;
  border-radius: 4px;
  min-width: 360px;
  height: 420px;
  padding: 28px 16px 4px 16px;
  overflow-y: scroll;
  margin-right: 32px;
  width: 33.3%;
  margin-top: 28px;
  position: relative;
}

.search_words_title {
  font-size: 12px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #000000;
  opacity: 0.5;
}

.order_pos_container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.info_props_container {

  display: block;
  min-width: 200px;
  width: 33.3%;
  border-right: 1px solid #E7EEF2;
  padding-right: 32px;
  margin-top: 28px;
}

.grey_text {
  font-size: 14px;
  line-height: 28px;
  color: rgb(0 0 0 / 50%);
}

.center_text {
  text-align: center;
}

.minimal_price_univ {
  font-size: 10px;
  line-height: 12px;
  color: #000000;
  opacity: 0.5;
  margin-top: 8px;
}

.order_pos_text {
  font-size: 14px;
  line-height: 28px;
  color: black;
  max-width: 100%;
}

.position_item {
  margin-left: 30px;
  width: 32px;
  height: 24px;
  position: relative;
}

.current_position {
  text-align: right;
  margin-top: 4px;
  color: black;
}

.eye_icon {
  cursor: pointer;
  margin-top: 4px;
}

.eye_icon:hover {
  opacity: 0.7;
}

.after_pos {
  margin-left: 32px;
  text-align: right;
}

.search_words_title_container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.pause_play_info {
  color: #1696EC;
  margin-left: 32px;
  display: flex;
  cursor: pointer;
}

.pause_play_info:hover {
  opacity: 0.7;
}

.pause_play_info_text {
  /* margin-top: 5px; */
  position: relative;
  /* top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); */
  margin-left: 10px;
  margin-top: auto;
  margin-bottom: auto;
}

.up_balance_info_text {
  margin-top: 2px;
  margin-left: 10px;
}

.center_req {
  justify-content: center;
  display: flex;
  cursor: pointer;
}

.center_icons {
  justify-content: center;
  display: flex;
}

.center_req:hover {
  opacity: 0.7;
}

.order_req {
  margin: 10px 10px 0px 10px;
  color: grey;
  font-size: 11px;
  width: 40px;
}

.installs_desc {
  margin-left: 8px;
  color: grey;
  font-size: 12px;
  margin-top: 2px;
  color: #000000;
  opacity: 0.5;
}

.date_install {
  margin-top: 10px;
  font-size: 11px;
}

.order_req_desc {
  display: block;
  font-size: 14px;
  font-family: OpenSans-Semibold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  margin: 8px 10px 0px 00px;
  cursor: pointer;
}

.order_req_desc:hover {
  opacity: 0.7;
}

.installs {
  font-size: 14px;
}

.installs_max {
  font-size: 11px;
  min-width: fit-content;
}

.installs_container {
  padding: 10px 10px 0px 10px;
  display: flex;
  cursor: pointer;
}

.installs_container:hover {
  opacity: 0.7;
}

.status_container {
  background: #E7F6FF;
  height: 55px;
  margin: 10px -12px -12px -12px;
  display: flex;
  padding: 0px 20px 0px 10px;
  justify-content: space-between;
}

.control_container {
  background: #E7F6FF;
  text-align: center;
  margin: 10px 0px 0px 0px;
  display: flex;
  padding: 12px
}

.status_text_control {
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  margin-bottom: 8px;
  color: #000000;

  opacity: 0.5;
}

.left_10 {
  margin-left: 10px;
}

.controlls {
  display: block;
  width: 100%;
  position: relative;
}

.blocked_help {
  margin: 2px 0 0 6px;
}

.install_container {
  width: calc(100% + 4px);
  margin-left: -2px;
  background: white;
  display: flex;
  justify-content: space-between;
}

.status_text {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.progress_line_container {
  height: 6px;
  width: calc(100% + 4px);
  position: relative;
  margin-left: -2px;
}

.progress_line_bg {
  height: 6px;
  width: 100%;
  background: #e4ecef;
}

.progress_line {
  height: 6px;
  z-index: 9;
  position: absolute;
  left: 0;
  top: 0;
  background: #f0ad4e;
  transition: width 0.3s;
}

.filter_text {
  font-size: 14px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #478CC8;
}

.filter_container {
  display: flex;
  margin-bottom: 40px;
  width: fit-content;
  cursor: pointer;
}

.arrow_filter {
  margin-left: 6px;
  margin-right: 25px;
}

.filter_container:hover .filter_text {
  opacity: 0.7;
}

.filter_container:hover .arrow_filter {
  opacity: 0.7;
}

.filters {
  display: flex;
}

.left_25 {
  padding-left: 25px;
}

.stats_title_text {
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  margin-bottom: 30px;
  /* lk_left_menu */
  color: #2F3D48;
}

.stat_chart {
  margin-left: -16px;
}

.agreement_container {
  padding-top: 200px;
  height: auto;
  min-height: 100vh;
  background: white;
  position: absolute;
  top: -60px;
  left: 0;
  text-align: left;
  z-index: 9999999999;
  padding-left: 20%;
  padding-right: 20%;
}

@media (max-width: 1486px) {
  .order_container {
    width: calc(100% / 4 - 28px);
  }
}

@media (max-width: 1354px) {
  .order_container {
    width: calc(100% / 3 - 28px);
  }
}

@media (max-width: 1268px) {
  .order_container {
    width: calc(100% / 5 - 28px);
  }
}

@media (max-width: 1200px) {
  .order_container {
    width: calc(100% / 4 - 28px);
  }
}

@media (max-width: 970px) {
  .order_container {
    width: calc(100% / 3 - 28px);
  }
}

.orders_bot_prices {
  margin-right: 24px;
  margin-bottom: 24px;
}

.settings_email {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: black;
  margin-left: 12px;
  margin-top: auto;
  margin-bottom: auto;
}

.admin_controll_line {
  display: block;
}

@media (max-width: 768px) {
  .order_price_android {
    margin-left: 10px;
    margin-bottom: 5px;
  }

  .admin_controlls_row {
    overflow-x: scroll;
  }

  .admin_controll_line {
    display: none;
  }

  .order_price_ios {
    margin-left: 34px;
    margin-top: 5px;
  }

  .order_price_ios_world {
    margin-left: 36px;
  }

  .android_ml {
    margin-bottom: 5px;
  }

  .ios_ml {
    margin-top: 5px;
  }

  .create_order_j_emoji {
    margin: auto 0;
  }

  .create_order_prices {
    font-size: 12px;
  }

  .create_order_just {
    display: block;
    margin-bottom: 16px;
  }

  .create_order_j_flex {
    display: block;
  }

  .reviewPlashka {
    left: 0;
    width: 100vw;
  }

  .order_row {
    display: block;
  }

  .filters {
    display: block;
    width: 100%;
  }

  .order_container {
    width: calc(100% / 2 - 28px);
  }

  /* .order_container{
   
    width: 100%;
    margin-right: 0px;
    margin-bottom: 52px;
  } */
  .text-cost-left {
    margin-left: 0px;
    margin-top: 0px;
  }

  .mobile_cost {
    display: flex;
    margin-bottom: 16px;
  }

  .text-cost-right {
    margin-top: 0px;
  }

  .land_cost {
    display: none;
  }

  .progress_top {
    opacity: 1;
    transform: rotate(-45deg);
    font-size: 12px;
  }

  .text_progress_order {
    font-size: 12px;
  }
}

.margin_r_16 {
  margin-right: 16px;

}

.text_center {
  text-align: center;
}

.yellow_line {
  height: 1px;
  background: #56CCF2;
  width: calc(100% - 8px);
  margin-top: 8px;
}

.chart_width_1 {
  /* width: calc(100% / 14); */
  width: calc((100% - 6px) / 13);
}

.yellow_round {
  height: 6px;
  width: 6px;
  margin: 4.5px 0px 0px 0px;
  border-radius: 100px;
  border: 1px solid #56CCF2;
}

.gray-back {
  background: #dedede;
  margin-left: 15px;
  width: 470px;
  margin-top: 10px;
  padding-top: 8px;
}

.text-referals {
  color: black;
  font-size: 13px;
  margin-top: 10px;

}

.text-referals-1 {
  width: 100px;
}

.text-referals-2 {

  margin-left: 35px;
  width: 170px;
}

.text-referals-3 {
  text-align: right;
  margin-left: 55px;
  width: 100px;
}

.text-keywords-first {
  font-size: 14px;
  line-height: 28px;
  color: #000000;
  width: 100%;
  margin-top: 9px;
}

.text_keywords_position {
  font-size: 14px;
  line-height: 28px;
  color: #000000;
  margin-left: 24px;
  margin-right: 16px;
  margin-top: 8px;
}

.keywords_line {
  width: 100%;
  height: 48px;
}

#upgrade-btn {
  background-color: #f6f9fb;
  border-width: 0px;
  font-size: 12px;
  padding: 2px;
  font-family: OpenSans-Semibold;
  width: 80px;
  height: 25px;
  color: black;
  margin-left: 32px;
  margin-top: 5px;
}

#keyword-checked {
  margin-left: 32px;
  margin-bottom: -5px;
}

.keyword_checked {
  margin-left: 16px;
  margin-top: 14px;
  margin-right: 24px;
}

.text-keywords-top-first {
  color: black;
  font-family: OpenSans-Semibold;
  font-size: 12px;
  margin-left: 20px;
  width: 250px;
}

.text-keywords-top-last-2 {
  color: black;
  font-family: OpenSans-Semibold;
  font-size: 12px;
  margin-left: 15px;

  width: 70px;
}

.text-keywords-top-last {
  color: black;
  font-family: OpenSans-Semibold;
  font-size: 12px;
  margin-left: 45px;

  width: 70px;
}

.text-keywords-index {
  color: black;
  font-size: 12px;
  margin-top: 10px;
  margin-left: 20px;
  align-items: center;
  width: 30px;
  font-family: OpenSans-Semibold;
}

.text-keywords-index-ips {
  color: black;
  font-size: 12px;
  margin-left: 20px;
  align-items: center;
  width: 30px;
  min-width: 300px;
  font-family: OpenSans-Semibold;
}

.text-referals-top {
  color: black;
  font-size: 14px;
  font-family: OpenSans-Bold;

}

.text-referals-top-1 {

  width: 100px;
}

.flex-cont {
  width: 100%;
  display: flex;
}

.text-referals-top-2 {

  margin-left: 34px;
  width: 170px;
}

.text-referals-top-3 {
  text-align: right;
  margin-left: 65px;
  width: 100px;
}

.text-keywords-top {
  color: black;
  font-family: OpenSans-Semibold;
  font-size: 12px;
  margin-left: 20px;
  text-align: center;
  width: 50px;
}

.text-keywords-top-index {
  color: black;
  font-family: OpenSans-Semibold;
  font-size: 12px;
  margin-left: 20px;

  width: 30px;
}

.text-position {
  color: black;
  font-family: OpenSans-Semibold;
  font-size: 13px;

  margin-left: 40px;

}

.text-key-grey-back {
  background: #f6f9fb;
  margin-top: 10px;
  padding-top: 8px;
  width: 590px;
}

.icon-minus {
  max-width: 50px;
  max-height: 50px;
  margin-top: 5px;
}

.icon-plus {
  margin-top: 5px;
  max-width: 50px;
  max-height: 50px;
  margin-left: 20px;
}

#border-2 {
  color: #7f7f7f;
  padding-top: 8px;
  height: 60px;
  min-height: 60px;
  background: #fff;
  border: 2px solid #e4ecef;
  /* Белая рамка */
  border-radius: 4px;
  /* Радиус скругления */
  font-size: 11px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#wrapper {
  padding-top: 50px;
  padding-left: 0;
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
  -o-transition: all .5s ease;
  transition: all .5s ease;
}

.ref-link-container {
  color: #7f7f7f;
  background: #E7F6FF;

  height: 60px;
  min-height: 60px;
  /* Белая рамка */
  border-radius: 4px;
  /* Радиус скругления */
  font-size: 11px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (min-width: 992px) {
  #wrapper {
    padding-left: 225px;
  }
}

@media (min-width: 992px) {
  #wrapper #sidebar-wrapper {
    width: 225px;
  }
}

#sidebar-wrapper {
  border-right: 1px solid #e7e7e7;
}

#sidebar-wrapper {
  z-index: 1000;
  position: fixed;
  left: 225px;
  width: 0;
  height: 100%;
  margin-left: -225px;
  overflow-y: auto;
  background-color: #f2f2f2;
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
  -o-transition: all .5s ease;
  transition: all .5s ease;
}

#sidebar-wrapper .sidebar-nav {
  position: absolute;
  top: 0;
  width: 225px;

  font-size: 14px;
  margin: 0;
  padding: 0;
  list-style: none;
}

#sidebar-wrapper .sidebar-nav li {
  text-indent: 0;
  line-height: 45px;
}

#sidebar-wrapper .sidebar-nav li a {
  display: block;
  text-decoration: none;
  color: #1696EC;
}



#sidebar-wrapper .sidebar-nav li a .sidebar-icon {
  width: 45px;
  height: 45px;
  font-size: 14px;
  padding: 0 2px;
  display: inline-block;
  text-indent: 7px;
  margin-right: 10px;
  color: #fff;
  float: left;
}

#sidebar-wrapper .sidebar-nav li a .caret {
  position: absolute;
  right: 23px;
  top: auto;
  margin-top: 20px;
}


#sidebar-wrapper .sidebar-nav li ul.panel-collapse {
  list-style: none;
  -moz-padding-start: 0;
  -webkit-padding-start: 0;
  -khtml-padding-start: 0;
  -o-padding-start: 0;
  padding-start: 0;
  padding: 0;
}

#sidebar-wrapper .sidebar-nav li ul.panel-collapse li i {
  margin-right: 10px;
}

#sidebar-wrapper .sidebar-nav li ul.panel-collapse li {
  text-indent: 15px;
}


#text-black {
  color: black;
  margin-right: 10px;
}

#package-row {
  padding-right: 40px;
  padding-top: 15px;

}

.img-app-icon {
  max-width: 20px;
  max-height: 20px;
  border-radius: 4px;

}

.img-device-icon {
  max-width: 15px;
  max-height: 15px;
  border-radius: 4px;
  margin-bottom: 3px;
  margin-right: 5px;
  opacity: 0.5;

}

.img_device_icon {
  height: 20px;
  margin-bottom: 3px;
  margin-right: 12px;
  margin-top: 2px;
}

.img_device_icon:hover {
  opacity: 0.7;
}

.img_device_icon_input {
  height: 20px;
  margin-bottom: 3px;
  margin-right: 12px;
  margin-top: 2px;
  display: none;
}

.app-info {
  visibility: collapse;
  display: none;
  margin-top: -100px;
}

.shop-card {
  border: 2px solid #e7eff2;
  /* Белая рамка */
  border-radius: 0px;
  /* Радиус скругления */
  /*box-shadow: 2px 5px 10px #dfdede;*/
  margin: 15px auto;
  padding: 20px;
  height: 200px;
  min-width: 250px;

  font-family: OpenSans-Regular;
}

.shop-card a:hover {}

.icon-placeholder {
  height: 110px;
  margin: 0;


}

.center-image {
  display: block;
  margin-left: auto;
  margin-right: auto
}

/*.icon-placeholder-bank{
.icon-placeholder{
height: 110px;
margin-left: 60px;
margin-top: -40px;
}
}*/
#graphic-row {
  padding-right: 30px;
  padding-top: 30px;
  margin-left: -20px;
}

#package-row1 {
  padding-right: 40px;
  padding-top: 30px;
  margin-left: 16px;
}

#package-row-position {
  padding-right: 40px;
  padding-top: 20px;
  margin-left: 30px;
  margin-bottom: 20px;
}

#package-row-switch {
  padding-right: 15px;
  padding-top: 30px;
  margin-left: 0px;
}


#package-no-margin-top {
  padding-right: 15px;
  padding-top: 10px;
  margin-left: 16px;
}

#package-money {
  padding-right: 1px;
  padding-top: 15px;
  margin-left: 1px;
}

#package-money-add {

  padding-top: 10px;
  margin-bottom: 10px;

}

#package-row-0 {
  padding-right: 40px;
  padding-top: 0px;
  margin-left: 30px;
}

.left-30 {
  margin-left: 30px;
}

#package-row-5 {
  padding-right: 40px;
  padding-top: 5px;
  margin-left: 16px;
}

#work-space {
  display: -ms-flexbox;
  display: flex;
  min-height: 100%;
  -ms-flex: 1;
  flex: 1;
}

.cabinetAside {
  width: 256px;
  min-height: 100%;
  background: #112A3D;
  color: #ffffff;
  position: fixed;
  /* Set the navbar to fixed position */


}

@media (max-width: 992px) {
  #wrapper #sidebar-wrapper {
    width: 45px;
  }

  #wrapper #sidebar-wrapper #sidebar #sidemenu li ul {
    position: fixed;
    left: 45px;
    margin-top: -45px;
    z-index: 1000;
    width: 200px;
    height: 0;
  }
}

.text-pre-subscr {
  font-size: 15px;
  margin-left: 15px;
  margin-top: 4px;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 56px;
  height: 30px;
  min-width: 56px;
}

/* Hide default HTML checkbox */
.switch input {
  display: none;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #C9D5DA;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #FFE141;
}

input:focus+.slider {
  background-color: #FFE141;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.copy-link-description {
  border: 1px solid #FFE141;
  border-radius: 4px;
  color: black;
  text-align: left;
  margin-bottom: 16px;
  display: block;
  padding: 16px;
  font-size: 14px;
  margin-right: 30px;
  width: 100%;
}

.copy-btn_link {

  background-color: #FFE141;
  border-width: 0px;
  font-size: 14px;
  color: black;

  font-size: 15px;
  font-family: OpenSans-Bold;
  border-radius: 0px 4px 4px 0px;
  cursor: pointer;
}

.copy-btn_link:hover {
  background-color: #EED710;
  color: black;
}

.left-link {
  width: 70%;
  margin-left: 8px;
  color: black;
  border-width: 0px;
  font-size: 14px;
}

.left-right-15 {
  margin-left: 15px;
  margin-right: 15px;
}

.top-15 {
  margin-top: 15px;
}

.ref_min {
  min-width: 500px;
  padding: 5px 0px 15px 0px;
  border-bottom: 1px solid #e7eff2;
}

.subscription-text {
  color: #959595;
  font-size: 12px;
  width: 100%;
  margin-left: 20px;
}

.subscription-text-referals {
  color: black;
  font-size: 13px;
}

.plashka-text {
  color: black;
  font-size: 16px;
  padding-right: 5px;
}

.subscription-text-referals-semibold {
  color: black;
  font-size: 13px;
  width: 100px;
}

.subscription-text-switch {
  color: #959595;
  font-size: 12px;
  padding-left: 26px;
  padding-right: 40px;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #bfbfbf;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #bfbfbf;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #bfbfbf;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #bfbfbf;
}

#start-campaign-btn {
  background-color: #FFE141;
  border-width: 0px;
  font-size: 14px;
  padding: 5px;
  color: black;
  width: 250px;
  height: 50px;
  font-size: 15px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2),
    0 1px 4px 0 rgba(0, 0, 0, 0.19);
  font-family: OpenSans-Bold;
  outline: none;
}

#start-campaign-btn:hover {
  background-color: #EED710;

}

.yellow_btn {
  background-color: #FFE141;
  color: black;
  border-radius: 4px;
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  font-family: OpenSans-Bold;
  outline: none;
  font-size: 15px;
  cursor: pointer;
  transition: all 0.3s;
  height: fit-content;
}

.create_order_btn {
  width: fit-content;
  width: fit-content;
  padding-left: 60px;
  padding-right: 60px;
  /* box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 4px 0 rgba(0, 0, 0, 0.19); */
  /* background-color: white; */
  background: #FFE141;
  box-shadow: 0px 4px 15px rgba(246, 221, 0, 0.4);
  border-radius: 4px;
  /* height: 55px; */
  padding: 18px 74px 18px 74px;
  font-family: OpenSans-Regular;
  font-weight: 600;
}

.create_order_container {
  display: flex;
  margin-top: 30px;
}

.yellow_btn:hover {
  opacity: 0.7;
}

.menu_btn {
  width: 210px;
  margin-bottom: 10px;
  margin-left: 40px;
  margin-top: 16px;
}

.menu_btn:hover {
  background-color: #EED710;
}

.sdk_appeal_item {
  background: rgba(255, 150, 150, 0.15);
  border-radius: 4px;
  width: -webkit-fill-available;
  padding: 12px 16px;
  margin-bottom: 32px;
  margin-left: 48px;
}

.alert_triangle {
  height: 16px;
  width: 16px;
  margin-right: 8px;
}

.sdk_appeal_title {
  font-weight: bold;
  font-size: 14px;
  line-height: 150%;
  color: #FF6262;
  margin-top: -2px;
}

.sdk_appeal_text {
  font-size: 14px;
  line-height: 150%;
  color: #000000;
  margin: 8px 0px;
}

.sdk_appeal_desc {
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */

  color: #000000;

  opacity: 0.7;
}

#share-btn {
  background-color: #FFE141;
  border-width: 0px;
  font-size: 14px;
  padding: 5px;
  color: black;
  width: 210px;
  height: 50px;
  font-size: 15px;
  margin-left: 30px;
  margin-bottom: 20px;
}

#make-purchase-btn {
  background-color: #FFE141;
  border-width: 0px;
  font-size: 14px;
  padding: 5px;
  color: black;
  width: 120px;
  height: 40px;
  font-size: 15px;
  margin-left: 0px;
  margin-bottom: 10px;
  margin-top: 15px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2),
    0 1px 4px 0 rgba(0, 0, 0, 0.19);
  font-family: OpenSans-Bold;
  outline: none;
}

#make-purchase-btn:hover {
  background-color: #EED710;

}

#start-campaign-btn-orders {
  background-color: #FFE141;
  border-width: 0px;
  font-size: 14px;
  padding: 8px;
  color: black;
  width: 200px;
  height: 40px;
  float: right;
  margin-right: 15px;
  margin-top: 10px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2),
    0 1px 4px 0 rgba(0, 0, 0, 0.19);
  font-family: OpenSans-Bold;
  outline: none;

}

#start-campaign-btn-orders:hover {
  background-color: #EED710;

}

#enter_in_zerg {
  background-color: #FFE141;
  border-width: 0px;
  font-size: 14px;
  padding: 5px;
  color: black;
  width: 150px;
  height: 35px;
  float: right;
  margin-right: 60px;

}

#enter_in_zerg_center {
  background-color: #FFE141;
  border-width: 0px;
  font-size: 14px;
  padding: 5px;
  color: black;
  width: 250px;
  margin-top: 10px;
  height: 40px;



}

#start-campaign-btn-keywords-top {
  background-color: #FFE141;
  border-width: 0px;
  font-size: 14px;
  padding: 5px;
  color: black;
  width: 240px;
  height: 40px;
  margin-right: 15px;
  margin-top: 10px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2),
    0 1px 4px 0 rgba(0, 0, 0, 0.19);
  font-family: OpenSans-Bold;
  outline: none;

}

.padding_left_mobile {
  padding-left: 24px;
}

.keywords_top_btn {
  background-color: #FFE141;
  border-width: 0px;
  font-size: 14px;
  padding: 5px;
  color: black;
  width: 240px;
  min-width: 240px;
  height: 40px;
  margin-right: 15px;
  margin-top: 0px;
  margin-left: 32px;
  padding-left: 20px;
  padding-right: 20px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2),
    0 1px 4px 0 rgba(0, 0, 0, 0.19);
  font-family: OpenSans-Bold;
  outline: 0;
  cursor: pointer;

}

.keywords_top_btn:hover {
  opacity: 0.7;
}

#start-campaign-btn-keywords-top:hover {
  background-color: #EED710;

}

#create-btn-order {
  background-color: #FFE141;
  border-width: 0px;
  font-size: 22px;
  padding: 5px;
  color: black;
  width: 200px;
  height: 50px;


  margin-bottom: 150px
}

.menu_line {
  margin-left: 40px;
  margin-right: 40px;
  opacity: 0.2;
  border: 1px solid #FFFFFF;
  height: 0px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.menu-text {
  color: #f2f2f2;
  margin-top: 10px;

}

.left-menu-text {
  color: #f2f2f2;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
  pointer-events: none;
}

.left-menu-text-li {
  color: rgba(255, 255, 255, 0.7);
  padding-top: 21px;
  padding-bottom: 21px;

  padding-left: 60px;
  cursor: pointer;
  font-size: 14px;
  font-family: OpenSans-SemiBold;
  outline: 0;
  transition: all 0.3s;
}

.men_desc_text {
  font-family: OpenSans-Regular;
  font-size: 14px;
  line-height: 140%;
  opacity: 0.7;
  color: rgba(255, 255, 255, 0.7);
}

.selected_menu {
  color: rgba(255, 255, 255, 0.7);
  padding-top: 21px;
  padding-bottom: 21px;
  padding-left: 60px;
  cursor: pointer;
  font-size: 14px;
  font-family: OpenSans-SemiBold;
  outline: 0;
  /* background: #1F2B35; */
  color: white;

}

.menu_active_item {
  background: #0A1925;
}

.menu_item_new {
  display: flex;
  width: 100%;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.menu_item_new:hover {
  background: #0A1925;
  color: white;
  outline: 0;
}

.menu_item_new a {
  transition: all 0.3s ease-in-out;
}

.menu_item_new img {
  transition: all 0.3s ease-in-out;
}

.menu_item_new:hover a {
  color: white;
}

.menu_item_new:hover img {
  opacity: 1;
}

.menu_item_blog:hover {
  background: #0A1925;
  color: white;
  outline: 0;
}

.menu-text-bot {
  color: #959595;
  font-size: 12px;
  margin-top: 10px;
  bottom: 20px;
  /* Расстояние от нижнего края окна браузера */
  position: absolute;
  /* Абсолютное позиционирование */

}

.menu-text-bot-2 {
  color: #959595;
  font-size: 12px;
  margin-top: 10px;
  bottom: 40px;
  /* Расстояние от нижнего края окна браузера */
  position: absolute;
  /* Абсолютное позиционирование */

}

.menu-text-bot-3 {
  color: #959595;
  font-size: 12px;
  margin-top: 10px;
  bottom: 60px;
  /* Расстояние от нижнего края окна браузера */
  position: absolute;
  /* Абсолютное позиционирование */

}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

.single-blog-item {
  border: 2px solid #e7eff2;
  /* Белая рамка */
  border-radius: 0px;
  /* Радиус скругления */
  /*box-shadow: 2px 5px 10px #dfdede;*/
  margin: 15px auto;
  padding-right: 10px;
  padding-top: 10px;
  min-width: 200px;
  font-family: OpenSans-Regular;

}

md-radio-button.my-radio .md-off {
  border-color: #0e5ef1;
}

md-radio-button.md-checked.my-radio .md-on {
  background-color: #0e5ef1;
}

.row-padding-left {
  padding-left: 32px;
}

.blog-content {
  padding: 15px;
}

.blog-content h4 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.blog-content h4 a {
  color: #777;
}

.blog-content p {
  color: #999;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.3333;
}

.blog-date {}

.blog-date {
  position: absolute;
  background: #337ab7;
  top: 35px;
  left: 5px;
  color: #fff;
  border-radius: 0 25px 25px 0;
  padding: 5px 15px;
  font-weight: 700;
}

.more-btn {
  background: #337ab7;
  border-radius: 2px;
  display: block;
  height: 30px;
  line-height: 30px;
  margin: 30px auto auto auto;
  text-align: center;
  width: 110px;
  color: #f1f1f1;
}

.controlls_center {
  justify-content: center;
}

.site_seo_time {
  font-weight: 700;
  font-size: 10px;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: var(--white);
  background: #b7c2c8;
  border-radius: 4px;
  padding: 2px;
  height: fit-content;
  width: fit-content;
  margin-top: 12px;
  margin-right: 8px;
  margin-left: 8px;
}

.controlls_bot_updat_text {
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  text-align: center;
  color: var(--black);
  opacity: 0.5;
  margin-top: 16px;
}

.controlls_bot_update {
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
}

.controlls_bot_icon {
  margin: -2px 4px 0 0;
  width: 24px;
  height: 24px;
}

#login-submit {
  background-color: #FFE141;
  color: black;
  font-family: OpenSans-Bold;
  border-width: 0px;
  background: #FFE141;
  box-shadow: 0px 4px 15px rgba(246, 221, 0, 0.4);
  border-radius: 4px;
  text-transform: none;
  margin-bottom: 20px;
  width: auto;
  padding-left: 30px;
  padding-right: 30px;
  transition: 0.5s;
  cursor: pointer;
  min-height: 48px;
  min-width: 212px;
}

#login-submit-reg-btn {
  background-color: #FFE141;
  color: black;
  font-family: OpenSans-Bold;
  border-width: 0px;
  background: #FFE141;
  box-shadow: 0px 4px 15px rgba(246, 221, 0, 0.4);
  border-radius: 4px;
  text-transform: none;
  margin-bottom: 30px;
  margin-top: 30px;
  min-height: 48px;
  width: auto;
  padding-left: 60px;
  padding-right: 60px;
  transition: 0.5s;
  cursor: pointer;

}

#login-submit-reg-btn-2 {
  background-color: #FFE141;
  color: black;
  font-family: OpenSans-Bold;
  border-width: 0px;
  background: #FFE141;
  box-shadow: 0px 4px 15px rgba(246, 221, 0, 0.4);
  border-radius: 4px;
  text-transform: none;
  margin-bottom: 30px;
  margin-top: 30px;
  width: 50%;
  padding-left: 60px;
  padding-right: 60px;
  transition: 0.5s;
  cursor: pointer;
  min-height: 48px;
}

#cancel-forgot-btn {

  color: black;
  font-family: OpenSans-Bold;
  border-width: 0px;
  background: white;
  box-shadow: none;
  border: 1px solid #FFE141;
  margin-right: 15px;
  border-radius: 4px;
  text-transform: none;
  margin-bottom: 30px;
  margin-top: 30px;
  width: 50%;
  padding-left: 30px;
  padding-right: 30px;
  background-color: white;
  transition: 0.5s;
  cursor: pointer;
}

@media (max-width: 768px) {
  .padding_left_mobile {
    padding-left: 16px;
  }

  .create_order_container {
    display: block;
    margin-top: 16px;
  }

  #cancel-forgot-btn {

    color: black;
    font-family: OpenSans-Bold;
    border-width: 0px;
    background: white;
    box-shadow: none;
    border: 1px solid #FFE141;
    margin-right: 15px;
    border-radius: 4px;
    text-transform: none;
    margin-bottom: 30px;
    margin-top: 30px;
    width: 45vw;
    padding-left: inherit;
    padding-right: inherit;
    background-color: white;
  }

  #login-submit-reg-btn-2 {
    background-color: #FFE141;
    color: black;
    font-family: OpenSans-Bold;
    border-width: 0px;
    background: #FFE141;
    box-shadow: 0px 4px 15px rgba(246, 221, 0, 0.4);
    border-radius: 4px;
    text-transform: none;
    margin-bottom: 30px;
    margin-top: 30px;
    width: 45vw;
    padding-left: inherit;
    padding-right: inherit;
  }
}

#cancel-forgot-btn:hover {
  background-color: #F5F5F5;
}

#login-submit-reg-btn-2:hover {
  background-color: #E7CF00;
}

#login-submit:hover {
  background-color: #E7CF00;
}

#login-submit-reg-btn:hover {

  background-color: #E7CF00;
}

.overflow_x {
  overflow-x: auto;
}

@media screen and (max-width: 768px) {}

.main_container {
  overflow: hidden;
  height: auto;
  margin: 20px 16px 8px 16px;
  padding: 40px 24px 40px 24px;
  width: -webkit-fill-available;

  min-height: calc(100vh - 50px);
  background: #FFFFFF;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 0px;
  font-size: 14px;
  position: relative;

}

.order_main {
  padding: 36px 0px 36px 24px;
  min-height: calc(100vh - 166px);
}

#center-container {

  margin-left: 12px;
  margin-bottom: 8px;
  margin-right: 16px;
  background: #fff;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 24px;
  padding-bottom: 24px;
  overflow-x: hidden;
  overflow: hidden;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.19);
  border-radius: 0px;
  /* Радиус скругления */
}

.center-container {

  margin-left: 12px;
  margin-bottom: 8px;
  margin-right: 16px;
  background: #fff;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 24px;
  padding-bottom: 24px;
  overflow-x: hidden;
  overflow: hidden;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.19);
  border-radius: 0px;
  /* Радиус скругления */
}

.padding_top_10 {
  padding-top: 10px;
  height: 48px;
}

.search_icon_seo {
  margin: 8px;
  height: 16px;
}

@media (max-width: 1200px) {

  /* .main_container{
    width: calc(100% - 80px);
  } */
  .order_main {
    width: calc(100% - 56px);
    min-height: calc(100vh - 166px);
    overflow-y: auto;
  }

  /* .header{
    width: calc(100% - 290px - 36px);
  } */
}

@media (max-width: 1024px) {

  /* .main_container{
    width: calc(100% - 80px);
  } */
  .order_main {
    width: -webkit-fill-available;
    ;
    min-height: calc(100vh - 152px);
  }

  /* .header{
    width: calc(100% - 36px);
  } */
}

@media (max-width: 768px) {

  .overflow_x {
    overflow-x: hidden;
  }

  #center-container {
    overflow-x: hidden;
    overflow: hidden;
    height: auto;
    margin-left: 8px;
    margin-bottom: 8px;
    margin-right: 8px;
    padding-left: 1px;
    padding-right: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    width: calc(100vw - 16px);
  }

  .main_container {
    position: relative;
    left: 0;
    height: auto;
    min-height: calc(100vh - 50px);
    margin: 20px 8px 8px 8px;
    padding: 36px 16px 36px 16px;
    /* width: calc(100% - 48px); */
  }

  .center-container {
    overflow-x: hidden;
    overflow: hidden;
    height: auto;
    margin-left: 8px;
    margin-bottom: 8px;
    margin-right: 8px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    width: calc(100vw - 16px);
  }
}

#row-top-pad {
  padding-top: 10px;
}

#row-order {
  margin-top: 20px;
  background: #E7F6FF;
  padding: 20px;
  border: 1px solid #E7F6FF;
  /* Белая рамка */
  border-radius: 2px;
  /* Радиус скругления */
}

#row-order-new {
  margin-top: 20px;
  margin-right: 20px;

  background: white;

  border: 1px solid #E7F6FF;
  /* Белая рамка */
  border-radius: 2px;
  /* Радиус скругления */
}

#container-order {

  padding: 10px;
}

#circle-icon-img {
  width: 80px;
  height: 80px;
  background-position: center center;
  background-repeat: no-repeat;
  border: 0px solid #E7F6FF;
  /* Белая рамка */
  border-radius: 8px;
  /* Радиус скругления */


}

.inine {
  float: none !important;
  display: inline-block !important;
}

.vertical-align {
  display: flex;
  align-items: center;
}

.flex {
  display: flex;
}

#text-in-input {
  color: #7E7F7C;
}

#first-menu {
  margin-top: 10px;
}



.wrap {
  position: relative;

}

.wrap .nav-bar .navbar-brand {
  margin-top: 15px;
}

.wrap .nav-bar .navbar-form {
  margin-top: 25px;
}

.wrap .nav-bar .nav {
  float: right !important;
}

.wrap .nav-bar .nav .dropdown span.fa,
.wrap .nav-bar .nav .dropdown span.caret {
  margin-right: 10px;
}

.wrap .nav-bar .nav .dropdown-menu {
  background: #222;
  left: auto;
  width: 200px;
  right: 0;
}

.wrap .nav-bar .nav .dropdown-menu>li>a {
  padding: 8px;
}

.wrap .nav-bar .nav .dropdown-menu>li>a:hover {
  background: #3355ff;
}

.wrap .side-menu-link {
  left: 21em;
}

.wrap .burger {
  position: relative;
  width: 35px;
  height: 30px;
  left: 10px;
  top: 10px;
  z-index: 500000;
}

.wrap .burger .burger_inside {
  position: absolute;
  background-color: #222;
  width: 30px;
  height: 5px;
  left: 2.5px;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}

.wrap .burger #bgrOne {
  top: 0;
}

.wrap .burger #bgrTwo {
  top: 10px;
}

.wrap .burger #bgrThree {
  top: 20px;
}

.wrap .side-menu {
  position: fixed;

  background: #112A3D;
  color: #fff;
  width: 290px;
  padding-top: 40px;


  float: left;
  display: block;
  left: 0;
  min-height: 100%;

}

.pay_comission_card {
  font-family: OpenSans-SemiBold;
  font-weight: 700;
  font-size: 14px;
  color: black;
}

.left-menu li a:hover {
  font-family: OpenSans-Regular;
  color: #FFE141;
}

.wrap .content {
  position: absolute;
  right: 0;
  height: 100%;
  min-width: 400px;

  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.wrap .content .top-bar {
  height: 40px;
  background: #E7F6FF;

}

.wrap ul.accordion {
  width: 100%;
  background: transparent;
}

.wrap ul.accordion .link {
  cursor: pointer;
  display: block;
  padding: 15px 15px 15px 42px;
  color: #9D9D9D;
  font-size: 14px;
  font-weight: 700;
  border-bottom: 1px solid #303030;
  position: relative;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.wrap ul.accordion li:not(open) last-child .link {
  border-bottom: 0;
}

.help_icon_height {
  height: 24px;
  margin-top: 4px;
}

.wrap ul.accordion li i {
  position: absolute;
  top: 16px;
  left: 12px;
  font-size: 18px;
  color: #999;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.wrap ul.accordion li i.fa-chevron-down {
  right: 12px;
  left: auto;
  font-size: 16px;
}

.wrap ul.accordion li.open .link {
  color: #FFB266;
}

.wrap ul.accordion li.open i {
  color: #FFB266;
}

.wrap ul.accordion li.open i.fa-chevron-down {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.wrap ul.accordion ul.submenu {
  display: none;
  background: transparent;
  font-size: 14px;
  padding: 0;
}

.wrap ul.accordion ul.submenu li {
  border-bottom: 1px solid #4b4a5e;
  list-style: none;
}

.wrap ul.accordion ul.submenu li a {
  display: block;
  text-decoration: none;
  color: #d9d9d9;
  padding: 12px;
  padding-left: 42px;
  -webkit-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.wrap ul.accordion ul.submenu li a:hover {
  background: rgba(240, 128, 128, 0.8);
  color: #ffb266;
}

@media screen and (max-width: 768px) {
  .wrap .nav-bar .navbar-brand {
    margin-top: 0;
    padding-left: 0;
  }

  .wrap .side-menu-link {
    display: inline-block;
  }

  .wrap .side-menu #qform {
    position: absolute;
    top: 10px;
  }

  .wrap .content {
    left: 0;

  }

  .wrap.active .content {
    left: 21em;
  }

  .wrap.active .content #bgrOne {
    top: 10px;
    transform: rotate(225deg);
  }

  .wrap.active .content #bgrTwo {
    opacity: 0;
  }

  .wrap.active .content #bgrThree {
    top: 10px;
    transform: rotate(315deg);
  }
}

@media screen and (min-width: 769px) {
  .side-menu-link {
    display: none;
  }

  .wrap {
    position: relative;
  }

  .wrap .content {
    left: 21em;
    right: 0;
  }
}

.cabinet-content {
  background: #E7F6FF;

  /*display: -ms-flexbox;
  display: flex;
  height: 100%;

  -ms-flex: 1;
  flex: 1;*/
}

#cabinet-center {
  background: #E7F6FF;
  padding-top: 20px;
  /*height: 100%;*/
  /* min-height: 100%; */
  width: 100%;

  overflow: hidden;

}

li {
  list-style-type: none;
  /* Убираем маркеры */
}

.left {
  float: left;
  margin-left: 25px;

}

.left-15 {
  float: left;
  margin-left: 15px;
  margin-top: 15px;

}

.right {
  float: right;
  margin-right: 15px;

}

.center {
  display: block;
  text-align: center;
  margin: auto;


}

#right-progress {
  float: right;
  margin-right: 20px;
  margin-top: -23px;
}

.logo {
  width: 96px;
  margin-top: 12px;
  margin-left: 30px;
  margin-bottom: 16px;
}

.logo-auth-zerg {
  width: 105px;

  margin-bottom: 5px;
}

.collapsed-icon {
  visibility: collapse;
  display: none;
}

.row-colored {
  background: #E7F6FF;
  height: 55px;
  margin-left: 0px;
  margin-right: -10px;
}

.text-money {
  font-size: 14px;
  width: 60px;
}

.text-process {
  font-size: 14px;
  width: 130px;
  float: left;
  margin-left: 30px;
  margin-top: -10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vertical-align {
  vertical-align: middle;
}

.icon-add {
  margin-top: -10px;
  float: right;

}

a:hover>img.hovered {

  display: inline;

}

.device_img {
  margin-top: 5px;
}

.icon-size-25 {
  margin-bottom: 10px;
  width: 25px;
  height: 25px;
  margin-right: 15px;
  float: right !important;

}

.icon-size-25-pause {
  margin-top: 15px;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.icon-size-25-pause:hover {
  opacity: 0.7;
}

.controll_icon {
  margin-top: 8px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.controll_icon:hover {
  opacity: 0.7;
}

.line_control {
  width: 2px;
  background: white;
  margin-top: -4px;
  margin-bottom: -4px;
}

.icon-size-25-icons-2 {

  width: 25px;
  height: 30px;
  margin-right: 25px;
  float: right;
  margin-top: 12px;


}

.padding_12 {
  padding: 12px;
}

.right-inline {

  display: inline-block !important;
  float: right !important;
}

.icon-size-20 {
  width: 23px;
  height: 23px;
}

.pause-icon {
  margin-left: 30px;

  width: 25px;
  height: 25px;
}

.center-text {
  display: block;
  text-align: center;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  font-family: OpenSans-Semibold;


}

.label-app {
  display: inline-block;
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 20px;
  text-align: center;
  max-width: 80%;
}

.content-app {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: OpenSans-Semibold;
  white-space: nowrap;
  text-align: center;
  max-width: 100%;
}

.content-request {
  display: block;

  font-size: 14px;
  font-family: OpenSans-Semibold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 90%;
  margin-right: 15px;
}

.text-bold {
  font-size: 14px;
  font-family: OpenSans-Semibold;
}

.center-text-app-name {
  text-align: center;
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  font-family: OpenSans-Semibold;


}

.text-left {
  margin-left: 30px;
  margin-right: 10px;
  color: grey;
  float: left;
  font-size: 11px;
  width: 40px;

}

.text-left-money {
  margin-left: 30px;
  margin-right: 25px;
  color: grey;
  float: left;
  font-size: 11px;
  width: 40px;

}

.container-links {
  margin-top: 30px;
  display: flex;
  margin-bottom: 30px;
}

.container-img-link-left {
  width: 50%;
  margin-right: 16px;
  outline: none;
}

.container-img-link-right {
  width: 50%;
  margin-left: 16px;
  outline: none;
}

.br-copy-btn {
  display: block;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

  text-align: center;
  width: 100%;
}

.copy-btn_text {
  font-size: 14px;
  width: 30%;
  height: 60px;
  position: relative;
}

.container-earned {
  display: flex;
  text-align: center;

}

.plashka-earned {
  border: 2px solid #e7eff2;
  border-radius: 4px;
  display: block;
  padding: 30px;
  width: 50%;
  position: relative;
}

.plashka-title {
  font-size: 20px;
  font-family: OpenSans-Bold;
}

.plashka-count-earned {
  font-size: 30px;
  font-family: OpenSans-Semibold;
  margin-bottom: 10px;
}

.referals-count {
  font-size: 16px;
  outline: none;
  font-family: OpenSans-Bold;
  color: #1696EC;
  cursor: pointer;
}

.container-earned-right {
  display: block;
  padding: 30px;
  margin-left: 30px;
  width: 50%;
}

.plasha-description {
  font-size: 13px;
  color: #909090;
  margin-bottom: 0px;
}

.referals-header {
  padding: 15px 0px;
  display: flex;
  border-bottom: 1px solid #e7eff2;
}

.referals-list {
  display: flex;
}

.top-15-refs {
  margin-top: 15px;
}

.right-plashka {
  display: flex;
  margin-top: 15px;
}

.aside__wrapper {
  min-height: 100%;
  height: 100%;
  width: 290px;
  display: flex;
  background: #112A3D;
  color: #fff;
  flex-direction: column;
  position: fixed;
}

.header__mobile-menu {
  display: none;
  width: 24px;
  cursor: pointer;
  outline: none;
  margin-left: 16px;
  margin-top: 6px;
  margin-right: 15px;


}

.header {

  background-color: #E7F6FF;
  display: flex;
  align-items: center;
  height: 60px;

}

.center_hor {
  position: relative;
  left: 50%;
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.center_vert {
  position: relative;
  top: 50%;
  -ms-transform: translate(0, 50%);
  transform: translate(0, 50%);
}

.app {
  display: flex;
  position: relative;
  width: 100%;
}

.ref_exchange_tooltip {
  position: absolute;
  top: -10px;
  z-index: 9;
}

.overlay {
  background: #112A3D;
  opacity: .75;
  width: 100%;
  height: 100%;
  position: fixed;
  border: 0px;
  z-index: 999;
}

.aside {
  flex-shrink: 0;
  width: inherit;
  max-width: 290px;
  background-color: #112A3D;
  color: #fff;
  z-index: 9999999999;
}

.landing-create-order {
  display: flex;
}

.mobile-create-order {
  display: none;
}

@media (max-width: 1268px) {
  .aside {
    position: absolute;
    top: 0;
    left: -290px;
    transition: .4s left;
    z-index: 10000;
    height: 100%;
  }

  .reviewPlashka {
    left: 0;
    width: 100vw;
  }
}

@media (max-width: 1024px) {
  .aside {
    position: absolute;
    top: 0;
    left: -290px;
    transition: .4s left;
    z-index: 10000;
    height: 100%;
  }

  #start-campaign-btn-orders {
    display: none;
  }
}

@media (max-width: 768px) {
  .app {
    flex-direction: column;
  }

  #start-campaign-btn-orders {
    display: none;
  }

  .landing-create-order {
    display: none;
  }

  .mobile-create-order {
    display: block;
  }

  .text_keywords_position {
    margin-right: 16px;
  }

}

@media (max-width: 1268px) {
  .header__mobile-menu {
    display: block;
  }
}

@media (max-width: 1024px) {

  #cabinet-center {
    width: 100vw;
  }

  .header__mobile-menu {
    display: block;
  }

  .header__logo {
    margin-left: 30px;
  }
}

.no-stats-container {
  visibility: visible;
  display: block;
  margin-top: 130px;
}

.keywords_container_landscape {
  display: flex;
  width: 100%;
}

.keywords_container_mobile {
  display: none;
}

@media (max-width: 768px) {
  .aside_header_mobile {
    height: 56px;
    background: #E7F6FF;
    display: block;

    visibility: visible;
  }

  .keywords_container_landscape {
    display: none;
  }

  .keywords_container_mobile {
    display: block;
  }

  .subscription-text {
    color: #959595;
    font-size: 12px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 8px;
  }

  .no-stats-container {
    visibility: visible;
    display: block;
    margin-top: 130px;
  }

  .stats-template {
    margin-left: 16px;
  }
}

.not_found_container {
  background: #F6F9FB;
  display: flex;
  height: 48px;
  width: 100%;
}

.not_found_text {
  font-size: 14px;
  line-height: 28px;
  color: #BF3028;
  margin-left: 16px;
  margin-top: 10px;
  margin-bottom: 0px;
}

.close_input_icon {
  width: 18px;
  height: 18px;
  position: absolute;
  cursor: pointer;
  right: 16px;
  top: 20px;
}

.close_input_icon:hover {
  opacity: 0.7;
}

@media (max-width: 768px) {
  .not_found_container {
    width: 100%;
  }

  .container-earned {
    display: block;

  }

  .keywords_top_btn {
    margin-left: 0px;
    width: 184px;
    min-width: 184px;
  }

  .left-right-15 {
    margin-left: 15px;
    margin-right: 1px;
  }

  .top-15-refs {
    margin-top: -25px;
  }

  .right-plashka {
    display: flex;
    margin-top: 30px;
  }

  .plashka-earned {

    width: auto;
  }

  .container-earned-right {
    margin-left: -30px;
    width: 100%;
  }

  .container-links {
    margin-top: 30px;
    display: block;
  }

  .container-img-link-left {
    width: 100%;
    margin-right: 0px;
  }

  .container-img-link-left:hover {
    opacity: 0.7;
  }

  .container-img-link-right:hover {
    opacity: 0.7;
  }

  .container-img-link-right {
    width: 100%;
    margin-left: 0px;
    margin-top: 32px;
  }

  .br-copy-btn {
    display: block;

    font-family: OpenSans-Semibold;
    white-space: normal;
  }

  .copy-btn_text {
    font-size: 12px;
    width: 35%;
    height: 60px;
    position: relative;
  }

  .left-link {
    width: 65%;

  }

}

.max-860-container {
  max-width: 860px;
}

.text-left-nav {

  margin-left: 15px;
  margin-right: 5px;
  margin-top: 30px;
  float: left;
  font-size: 14px;
  width: 40px;

}

.text-right-nav {
  font-family: OpenSans-Bold;
  margin-left: 25px;
  margin-right: 5px;
  margin-top: 30px;
  font-weight: bold;
  float: left;
  font-size: 14px;
  width: 100px;
}

.text-left-status {
  margin-left: 30px;
  margin-right: 35px;
  color: grey;
  float: left;
  font-size: 11px;
  width: 40px;
  margin-top: 10px
}

.text-left-progress {
  margin-left: 30px;
  margin-right: 15px;

  float: left;

}

.text-right-progress {
  float: right;
  margin-right: 15px;
  font-size: 11px;

}

.text-right {
  float: right;
  margin-right: 15px;
}

#progress-row {
  display: table-cell;
  vertical-align: bottom;
}

.v-center {
  position: relative;
  transform: translateY(50%);
}

.progress {
  background-image: -webkit-gradient(linear, left 0, left 100%, from(#e4ecef), to(#FFE141));
  background-image: -webkit-linear-gradient(top, #e4ecef 0, #FFE141 100%);
  background-image: -moz-linear-gradient(top, #e4ecef 0, #FFE141 100%);
  background-image: linear-gradient(to bottom, #e4ecef 0, #FFE141 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e4ecef', endColorstr='#FFE141', GradientType=0);
}

.progress {
  border-radius: 0px;
  margin-bottom: -10px;
  margin-top: -8px;
  margin-left: 30px;
  margin-right: 15px;
  height: 6px;
  background-color: #e4ecef;
  background-image: none;
  box-shadow: none;
}

.apps_drop_text {
  margin: 2px 0px 0px 0px;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: black;
  font-family: OpenSans-Regular;
}

.main_title {
  font-family: RobotoSlab-Bold;
  font-size: 32px;
  line-height: 28px;
  color: #000000;
  margin-bottom: 36px;
}

.input_container_border {
  color: #7f7f7f;
  height: fit-content;
  background: #fff;
  border: 2px solid #e4ecef;
  /* Белая рамка */
  border-radius: 4px;
  /* Радиус скругления */
  font-size: 11px;
  display: flex;
  padding: 10px 20px 14px 20px;
  width: 100%;
  margin-right: 20px;
  position: relative;

  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.5);
  font-family: OpenSans-Regular;

}

.switch_container {
  width: 100%;
  margin-right: 50px;
  position: relative;
}

.top_title {
  font-family: RobotoSlab-Bold;
  margin-left: 0px;
  font-size: 32px;
}

.first-task {
  font-family: OpenSans-Light;
  margin-top: 130px;
  margin-bottom: 20px;
  font-size: 28px;
}

#margin-top-check {
  margin-top: 13px;
  margin-left: 30px;
}

.stats-template {
  font-family: OpenSans-Light;
  margin-top: 20px;
  margin-bottom: 150px;
  font-size: 28px;
}

md-checkbox.md-checked.green .md-icon {
  background-color: rgba(0, 134, 193, 0.9);
}

.line-row {
  height: 1px;
  background: #E4ECEF;
  margin: 30px 0px 30px 0px;
}

.md-toolbar-tools {
  color: white;
}

.md-primary-blue {
  background: #1696EC;
}

.my-progress a {
  color: black;
}

.my-progress a:hover {
  color: grey;
}

#button-download-csv {
  margin-left: 18px;
  margin-bottom: 15px;

  border-width: 0px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: rgba(43, 147, 197, 0.92);
  color: white;
}

.my-panel-auth a {
  text-decoration: none;
  color: #666;
  font-weight: bold;
  font-size: 15px;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

a.active {
  color: #112A3D;
  font-size: 18px;
}

.zerg-title {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 20px;
  font-family: OpenSans-Semibold;
}

.tooltip__arrow {
  display: block;
  width: 50px;
  height: 25px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
}

.tooltip__arrow::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  background: white;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  left: 50%;
  box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.2);
}

.tooltip-bottom {
  top: 40px;
  left: 0;
  padding: 0 21px 6px 21px;
}

.tooltip-bottom .tooltip__arrow {
  bottom: 100%;
}

.tooltip-bottom .tooltip__arrow::after {
  top: 100%;
}

.tooltip-top {
  left: 0;
  bottom: 100%;
}

.tooltip-top .tooltip__arrow {
  top: 100%;
}

.tooltip-top .tooltip__arrow::after {
  top: 0;
}

.tooltip-left {
  right: 100%;
}

.tooltip-left .tooltip__arrow {
  width: 25px;
  height: 50px;
  top: 0;
  left: 100%;
  transform: initial;
}

.tooltip-left .tooltip__arrow::after {
  top: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  left: 0;
}

.rate-content__tooltip-arrow-withdrow {
  left: initial;

}

.rate-content__tooltip-arrow {
  left: initial;
  right: 0;
  transform: translateX(-12px);
}

.tooltip__container {
  position: absolute;
  z-index: 100;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  display: block;
}

.tooltip-top {
  left: 0;
  bottom: 100%;
}

.space_between {

  display: flex;
  justify-content: space-between;
}

.add_money_icon {
  margin-top: 6px;
  width: 25px;
  height: 25px;
  margin-right: 8px;
  cursor: pointer;
}

.add_money_icon:hover {
  opacity: 0.7;
}

.tooltip-top .tooltip__arrow {
  top: 0;
}

.loader-insta svg {
  overflow: visible;
  width: 100px;
  height: 150px;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.loader-insta svg g {
  animation: slide 2s linear infinite;
}

.loader-insta svg g:nth-child(2) {
  animation-delay: 0.5s;
}

.loader-insta svg g:nth-child(2) path {
  animation-delay: 0.5s;
  stroke-dasharray: 0px 158px;
  stroke-dashoffset: 1px;
}

.loader-insta svg path {
  stroke: url(#gradient);
  stroke-width: 7px;
  stroke-linecap: round;
  fill: none;
  stroke-dasharray: 0 157px;
  stroke-dashoffset: 0;
  animation: escalade 2s cubic-bezier(0.8, 0, 0.2, 1) infinite;
}

@keyframes slide {
  0% {
    transform: translateY(-50px);
  }

  100% {
    transform: translateY(50px);
  }
}

@keyframes escalade {
  0% {
    stroke-dasharray: 0 157px;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 156px 157px;
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dasharray: 156px 157px;
    stroke-dashoffset: -156px;
  }
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin: 22px;
  border: 2px solid #1696EC;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #1696EC transparent transparent transparent;

}

.choose-app {
  margin-bottom: 0px;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader-earn-container {
  margin: auto;
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  top: 0;
  width: 60px;
  height: 60px;
  bottom: 0;
}

.loader_input {
  position: absolute;
  display: block;
  right: 16px;
  top: 6px;
  width: 24px;
  height: 24px;
}

.tooltip-top .tooltip__arrow::after {
  top: 0;
}

.progress_bgstyle {
  position: absolute;
  top: -40px;
  left: 0;
  height: 100vh;
  z-index: 99999;
}

.rate-content__tooltip-wrapper {
  bottom: -80px;
  left: calc(50% - 120px);
  right: 0;
  height: 80px;
  z-index: 999999;
  background: white;
  width: 240px;
  margin-top: 60px;
}

.account-content-loader {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.plus-minus {
  padding-left: 16px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.stats_types {
  padding: 0px;
  margin: 0px;
}

.tooltip_bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -9;
}

.tooltip_bg_dates {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.apps_dropdown {
  background: #FFFFFF;
  border: 1px solid #E4ECEF;
  box-sizing: border-box;
  padding: 0px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  display: block;
  margin-left: -22px;
  position: absolute;
  z-index: 9999999;
  max-height: 428px;
  overflow-y: auto;
  width: calc(100% + 4px);
  top: 58px;
  padding-top: 6px;
  padding-bottom: 6px;
  cursor: auto;
}

.dropdown_ul {
  padding: 10px 0px 10px 0px;
}

.types_dropdown {
  background: #FFFFFF;
  border: 1px solid #E4ECEF;
  box-sizing: border-box;
  padding: 0px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  display: none;

  position: absolute;
  z-index: 9999;
  max-height: 500px;
  width: 180px;
  overflow-y: auto;
}

.app_in_drop {
  padding-left: 20px;
  padding-right: 28px;
  padding-top: 6px;
  padding-bottom: 6px;
  color: black;
  cursor: pointer;
  outline: 0;
}

.input_description_container {
  position: relative;
  width: 100%;
}

.input_description_text {
  font-size: 14px;
  line-height: 20px;
  color: var(--lk-left-menu);
  opacity: 0.8;
  margin-bottom: auto;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.input_container {
  height: 48px;
  background: #fff;
  border: 2px solid #E4ECEF;
  box-sizing: border-box;
  border-radius: 4px;
}

.input_text {
  color: black;
  border-width: 0px;
  font-size: 14px;
  padding-top: 12px;
  padding-left: 0px;
  padding-right: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.seo_text_header {
  font-size: 21px;
  line-height: 28px;
  margin-top: 51px;
  color: #000000;
  margin-bottom: 30px;
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: normal;
}

.search_keys_header {

  display: flex;
  justify-content: space-between;
}

.keywords_container {
  margin-top: 16px;
  margin-bottom: 20px;
}

.search_keys_header_request {
  margin-left: 60px;
  margin-bottom: 0px;
}

.search_container {
  display: flex;
  cursor: pointer;
}

.search_container:hover {
  opacity: 0.7;
}

.search_keys_header_position {
  margin-right: 16px;
  margin-bottom: 0px;
}

.arrow_keywords {
  margin-right: 16px;
  width: 12px;
  cursor: pointer;
  margin-top: 16px;
  margin-left: 16px;
  transform: rotate(180deg);
}

.arrow_keywords_container {
  display: block;
  cursor: pointer;
}

.arrow_keywords_container:hover {
  opacity: 0.7;
}

.keyword_task_container {

  position: fixed;
  bottom: 0;
  left: 290px;
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.2);
  padding: 8px;
  background: white;
  z-index: 99999;
  display: flex;
  max-height: 56px;
  width: calc(100% - 290px - 16px);
  -webkit-transition: max-height 0.5s;
  -moz-transition: max-height 0.5s;
  -ms-transition: max-height 0.5s;
  -o-transition: max-height 0.5s;
  transition: max-height 0.5s;
}

.proofs_mass_selected_img {
  margin-right: 12px;
  min-width: 24px;
  min-height: 24px;
  max-width: 24px;
  max-height: 24px;
}

.proofs_mass_selected_line {
  height: 1px;
  width: 100%;
  background: #C4D4E2;
}

.proofs_mass_selected {
  margin: 22px;
}

.mass_answer_container {
  position: fixed;
  bottom: 0;
  left: 290px;
  background: white;
  z-index: 9999;
  width: calc(100% - 290px - 16px);
  background: #DFEEFB;
  border: 1px solid #E7EEF2;
  box-sizing: border-box;
  border-radius: 0px 0px 4px 4px;

  -webkit-transition: max-height 0.5s;
  -moz-transition: max-height 0.5s;
  -ms-transition: max-height 0.5s;
  -o-transition: max-height 0.5s;
  transition: max-height 0.5s ease-out;
  overflow: hidden;
  max-height: 0px;
}

.mass_answer_margin {
  margin: 16px 20px 24px 16px;
}

.bot_40 {
  margin-bottom: 40px;
}

.keywords_picked {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  font-size: 14px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.7);

}

.seo_top_container {
  border: 1px solid #ECDA9C;
  box-sizing: border-box;
  border-radius: 24px;
  display: flex;
  width: 50%;
  height: 48px;
  position: relative;
  padding-left: 0px;
  padding-right: 0px;
}

.seo_top_inner {
  height: 100%;
  background: #FFF3C9;
}

.seo_percents_container {
  border-radius: 24px;
  height: 46px;
  padding-left: 0px;
  padding-right: 0px;
  width: 100%;
  position: absolute;

}

.seo_top_text {
  font-size: 14px;
  line-height: 150%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  left: 50%;
  font-family: OpenSans-Bold;
  color: #000000;
  z-index: 99999;
}

.seo_search_input {
  color: black;
  border-width: 0px;
  font-size: 14px;
  margin-left: 0px;
  padding-left: 0px;
}

.app_in_drop:hover {
  background: #E4ECEF;
}

.pay-title {
  margin-left: 0px;
}

.filter_stats {
  display: flex;
}

.filter_dates {
  display: block;
  position: relative;
}

.type_campaign {
  margin-left: 47px;
  margin-right: 47px;
}

.img_choose_app {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  margin-right: 12px;
}

.apps_line {
  padding: 8px 16px 8px 20px;
  cursor: pointer;
}

.apps_line:hover {
  background: #EAF4FA;
}

.img_choose_app_icon {
  display: none;
  height: 20px;
}

.button_choose_app {
  display: flex;
  border: 2px solid #e7eff2;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px 20px 16px 4px;
  outline: 0;
  cursor: pointer;
  width: 280px;
  margin-bottom: 32px;
  transition: border 0.3s;
}

.just_content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.just_content_auto {
  display: flex;
  justify-content: space-between;
}

.button_choose_app:hover {
  border: 2px solid #97c7ed
}

.left_16 {
  margin-left: 16px;
}

.button_choose_text {
  font-size: 14px;
  color: black;
  margin: 2px 16px 0px 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 146px;
}

.btn_filter_choose_text {
  margin: 2px 16px 0px 16px;
}

.drop_arrow {
  height: 10px;
  margin-top: 4px;
}

.right_popup {
  display: block;
  background: #FFFFFF;
  border: 1px solid #E4ECEF;
  box-sizing: border-box;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  position: absolute;
  right: 16px;
  top: 72px;
  z-index: 9999;
  padding: 10px 20px 10px 20px;
}

.right_popup_container {
  display: none;
}

.right_popup_exit {
  margin-bottom: 0px;
  font-size: 14px;
  line-height: 28px;
  display: flex;
  color: #478CC8;
  text-align: left;
  cursor: pointer;
}

.right_popup_bold {
  margin-bottom: 0px;
  font-size: 14px;
  font-family: OpenSans-Bold;
  display: flex;
  color: black;
  text-align: left;
}

.right_popup_text {
  margin-bottom: 0px;
  font-size: 14px;
  margin-bottom: 10px;
  display: flex;
  color: black;
  text-align: left;
}

.package-row-switch {
  padding-right: 1px;
  padding-top: 30px;
  margin-left: 16px;
}

.name_email {
  display: block;
}

.navbar_icon_right {
  display: none;
}

.img_div_nav {
  display: none;
  display: flex;
  margin-right: -20px;
  justify-content: flex-end;
}

.blue_text_filter {
  color: #1696EC;
  cursor: pointer
}

.date_text {
  width: 200px;
  border: 0px;
}

.black_text {
  color: #000000;
  margin-bottom: 14px;
}

.campaig_right {
  display: block;
}

.campaig_top {
  margin-top: 0px;

}

.container_aso {
  min-height: calc(100vh - 90px);
}

.text_tooltip_apps {
  margin: 0px;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.img_tooltip_icon {
  margin-right: 12px;
  display: flex;
  margin-left: -3px;
  background-position: center center;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
}

.img_input_icon {
  margin-right: 12px;
  display: none;
  margin-left: 4px;
  background-position: center center;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
}

.stats_text {
  font-family: OpenSans-regular;
  font-size: 21px;
  color: #4D4D4D;
}

.menu_plus_bg {
  margin-right: 12px;
  height: 24px;
  width: 24px;
  margin-top: 11px;

}

.menu_relative {
  position: relative;
}

.menu_plus_item {
  position: absolute;
  right: 0px;
  top: 8px;
  display: flex;
}

.empty_order_text {
  font-family: OpenSans-Light;
  margin-top: 30px;
  margin-bottom: 10px;
  font-size: 28px;
}

.stats_img {
  height: 160px;
}

.search_icon_container {
  border-radius: 500px;
  margin-left: 2px;
  margin-top: -4px;
  margin-bottom: 4px;
  width: 32px;
  height: 32px;
}

.keyword_line_bg {
  width: 100%;
  min-height: 48px;
  display: flex;
}

.keyword_picked_bot {
  margin-top: 3px;
}

.keyword_text_cont {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.input_text_search {
  text-overflow: ellipsis;
  border-width: 0px;
  width: 100%;
  margin-left: -2px;
  margin-top: 5px;
  font-size: 14px;
  line-height: 19px;
  font-family: OpenSans-Regular;
  color: black;
}

.input_text_search::placeholder {
  color: rgba(0, 0, 0, 0.5);

}

.search_test_border {
  padding: 13px 20px 18px 20px;
}

@media (max-width: 768px) {
  .main_title {
    font-family: RobotoSlab-Bold;
    font-size: 21px;
    line-height: 28px;
    color: #000000;
    margin-bottom: 16px;
  }

  .input_container_border {

    width: calc(100% - 40px);
    margin-right: 0px;

  }

  .subscription-text {
    margin-left: 0px;
  }

  .choose-app {
    margin-bottom: 6px;
  }

  .right_popup_container {
    display: block;
  }

  .keywords_picked {
    font-size: 12px;
    line-height: 150%;
    color: rgba(0, 0, 0, 0.7);

  }

  .arrow_keywords_container {
    margin-right: 8px;
  }

  .keyword_picked_bot {
    margin-top: 16px;
  }

  .input_description_container {
    margin-top: 20px;
  }

  .search_keys_header_position {
    margin-right: 16px;
    font-size: 12px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.5);
  }

  .search_keys_header_request {
    font-size: 12px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.5);
  }

  .keywords_line {
    width: 100%;
  }

  .search_keys_header {
    width: 100%;
  }

  .keywords_container {
    margin-left: -16px;
    margin-right: -16px;
  }

  .keyword_task_container {
    left: 0;
    width: 100%;
  }

  .mass_answer_container {
    left: 0;
    width: 100%;

  }

  .stats_img {
    height: 120px;
  }

  .stats_text {
    font-size: 14px;
  }

  .container_aso {
    min-height: calc(100vh - 72px);
  }

  .filter_dates {
    display: flex;
    position: relative;
  }

  .black_text {
    margin-right: 16px;
    margin-bottom: 0px;
  }

  .campaig_top {
    margin-top: 20px;
    margin-bottom: 6px;
  }

  .date_text {
    margin-left: 20px;
  }

  .apps_dropdown {
    width: calc(100vw - 48px);
    margin-left: -22px;
  }

  .button_choose_app {
    margin-left: 0px;
    width: 100%;
  }

  .campaig_right {
    display: block;
    margin-left: 20px;
  }

  .type_campaign {
    margin-left: 0px;
    margin-right: 0px;
    display: flex;
  }

  .right_popup {
    top: 60px;
  }

  .img_div_nav {
    display: flex;
    display: flex;
    margin-right: -20px;
    justify-content: flex-end;
  }

  .navbar_icon_right {
    display: block;
    height: 24px;
    margin-top: 15px;
    cursor: pointer;
    margin-right: 16px;
  }

  .name_email {
    display: none;
  }

  #make-purchase-btn {
    margin-left: 2px;
  }

  .top_title {
    margin-left: 16px;
  }

  .rate-content__tooltip-wrapper {
    margin-left: calc(50vw - 135px);
    right: auto;
    left: 0;

  }

  .filter_stats {
    display: block;
  }

  #text-black {
    margin-left: -15px;
  }

  #package-row-switch {
    padding-right: 0px;
    padding-top: 30px;
    margin-left: 0px;
  }

  .package-row-switch {
    padding-right: 1px;
    padding-top: 30px;
    margin-left: 30px;
  }

  .subscription-text-switch {
    color: #959595;
    font-size: 12px;
    padding-top: 8px;
    padding-left: 16px;
    padding-right: 16px;
  }

  #package-row1 {
    padding-right: 16px;
    padding-top: 30px;
    margin-left: 16px;
  }

  #package-row-5 {
    padding-right: 16px;
    padding-top: 5px;
    margin-left: 16px;
  }

  .left-30 {
    margin-left: 16px;
  }

  .plus-minus {
    padding-left: 0px;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .pay-title {
    margin-left: 16px;
  }

  #package-money {
    padding-right: 16px;
    padding-top: 15px;
    margin-left: 16px;
  }

}

.blue_hover {
  transition: 0.5s;
}

.blue_hover:hover {
  color: #046895;
  text-decoration: underline;
}

.blue_text {
  cursor: pointer;
  color: #1696EC;

}

#login-form-link {
  transition: 0.5s;
}

#login-form-link:hover {
  color: #046895;
}

#register-form-link {
  transition: 0.5s;
}

#register-form-link:hover {
  color: #046895;
}

.relative {
  position: relative;
}

.mobile_flex {
  display: flex;
}

.tooltip_bottom {
  top: 0;
  transform: rotate(180deg);
  margin-top: -25px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
}

.loader_orders {
  display: block;
  text-align: center;
  left: 0;
  padding-top: 40vh;

  right: 0;
  top: 0;

  bottom: 0;
}

.loader_background {
  display: none;
  background: #E7F6FF;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 9999999;
}

.btn-success {
  color: #fff;
  background: #478CC8;
  border-radius: 4px;
  border-color: #478CC8;
  font-family: OpenSans-Bold;
  outline: 0;
  font-size: 14px;
  line-height: 28px;

}

.btn-success:hover {
  opacity: 0.7;
  color: #fff;
  background: #478CC8;
  border-radius: 4px;
  border-color: #478CC8;
  font-family: OpenSans-Bold;
  outline: 0;
  font-size: 14px;
  line-height: 28px;
}

.btn-default {
  color: #478CC8;
  background-color: #FFFFFF;
  border-color: #FFFFFF;
  border: 0px;
  font-family: OpenSans-Bold;
  outline: 0;
  font-size: 14px;
  line-height: 28px;
}

.btn-default:hover {
  opacity: 0.7;
  color: #478CC8;
  background-color: #FFFFFF;
  border-color: #FFFFFF;
  border: 0px;
  font-family: OpenSans-Bold;
  outline: 0;
  font-size: 14px;
  line-height: 28px;
}

.btn-success[disabled] {
  opacity: 0.7;
  color: #fff;
  background: #478CC8;
  border-radius: 4px;
  border-color: #478CC8;
  font-family: OpenSans-Bold;
  outline: 0;
  font-size: 14px;
  line-height: 28px;
}

.padding_0 {
  padding-left: 0px;
}

.margin_l_56 {
  margin-left: 56px;
}

.margin_b_0 {
  margin: 0px;
}

.earn_with_us {
  padding-top: 10px;
  padding-bottom: 10px;
}

.navbar_left_text {
  font-size: 14px;
  display: block;
  margin-left: 16px;
}

.navbar_just {
  display: flex;
  overflow: hidden;
  justify-content: space-between;
}

.navbar_right_text {
  font-size: 14px;
  /* position: absolute; */
  cursor: pointer;
  text-align: right;
  outline: none;
  display: block;
  margin-right: 16px;
  padding-bottom: 8px;
  /* right: 16px; */
}

.ref_exchange_container {
  display: flex;
  position: relative;
  justify-content: center;
}

.header_bold {
  font-family: OpenSans-Bold;
  margin-top: 30px;
}

.create_order_exchange {
  margin-left: 8px;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  margin-top: 2px;
  opacity: 0.5;

}

.main {
  width: calc(100% - 290px);
}

.not_display {
  display: none;
}

.bold_money {
  font-family: OpenSans-Bold;
  padding-left: 8px;
  margin-top: 20px;
}

.filter_dropdown {
  margin: 36px 0px 0px -6px;
  top: auto;
  width: 280px;

}

.left_32 {
  margin-left: 32px;
}

.error_money_order {
  color: #FF6262;
  font-size: 14px;
}

.icon_warning {
  height: 15px;
  margin-right: 10px;
  margin-top: -5px;
}

.text_refs_bold {
  font-family: OpenSans-Bold;
  font-size: 15px;
}

.text_refs_red {
  color: #bf3028;
  font-size: 13px;
}

.send_ref {
  font-size: 15px;
  margin-top: 30px;
  font-family: OpenSans-Light;
}

.top_6 {
  margin-top: 6px;
}

.ref_link_desc {
  margin: 8px 8px 6px 8px;
}

.ref_link_container {
  width: 70%;
}

.banner_words {
  cursor: pointer;
  width: 100%;
}

.ref_container {
  margin-bottom: 100px;
}

.search_aso {
  width: calc(50% - 28px);
  margin-top: 24px;
}

.keywords_cont_all {
  width: 50%;
  margin-top: 24px;
}

.keywords_search {
  padding-right: 0px;
  padding-left: 0px;
  width: 100%;
}

.keywords_task {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.bot_0 {
  margin-bottom: 0px;
}

.keywords_mobile {
  display: flex;
  width: 100vw;
  justify-content: space-between;
}

.picked_keywords {
  margin-bottom: 0px;
  width: auto;
}

.img_24 {
  width: 24px;
  height: 24px;
  margin-right: 12px;
  margin-left: 2px;
}

.margin_0 {
  margin: 0px;
}

.prop_line {
  display: none;
  margin-bottom: 8px;
  position: relative;
}

.prop_line_img {
  top: 6px;
  position: absolute;
}

.pos_before {
  width: 80px;
  text-align: right;
}

.info_installs {
  width: 33.3%;
  margin-top: 28px;
  position: relative;
  min-width: 200px;
  margin-left: 32px;
  margin-bottom: 32px;
}

.line_info {
  height: 1px;
  background: #E7EEF2;
  margin-top: 168px;
  margin-bottom: 24px;
  width: calc(100% - 8px);
}

.line_info_budget {
  height: 1px;
  background: #E7EEF2;
  margin-top: 24px;
  margin-bottom: 24px;
  width: calc(100% - 8px);
}

.budget_text {
  text-align: center;
  font-size: 24px;
  margin-top: 12px;
}

.up_balance_info {
  color: #1696EC;
  display: flex;
  cursor: pointer;
}
.search_site_desc{
  font-weight: 400;
  font-size: 12px;
  line-height: 233%;
  color: var(--black);
  margin-top: 8px;
  opacity: 0.5;
  margin-bottom: -8px;
}
.up_balance_info:hover {
  opacity: 0.7;
}

.top_16 {
  margin-top: 16px;
}

.empty_orders_create {
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  font-size: 14px;
  padding: 14px 26px 14px 26px;
  border-radius: 8px;
}

.installs_error {
  font-size: 12px;
  margin-top: 10px;
  color: rgb(255, 98, 98);
}

.date_text_order {
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  margin-top: 2px;
  opacity: 0.5;
}

.date_text_order_info {
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  opacity: 0.5;
  margin-left: 20px;
  margin-top: 8px;
}

.red_text {
  color: #FF6262;
}

.order_name:hover {
  opacity: 0.7;
}

.universal_title {
  margin-bottom: 18px;
}

.universal_title_2 {
  font-size: 12px;
  line-height: 16px;
  color: #2F3D48;
  opacity: 0.8;
  border-bottom: 1px solid #E4ECEF;
  padding-bottom: 18px;
  margin-bottom: 24px;
}

.site_price_desc {
  font-size: 14px;
  line-height: 20px;
  color: var(--lk-left-menu);
  opacity: 0.8;
  margin-top: 32px;
}

.choose_type {
  width: 100%;
  display: block;
  margin-right: 20px;
  margin-bottom: 0px;
  position: relative;
}

.choose_type_title {
  font-size: 12px;
  line-height: 16px;
  margin-left: 20px;

  color: #000000;

  opacity: 0.5;
}

.help_nav {
  margin: 22px 0px 0px 6px;
  height: 16px;
  width: 16px;
}

.exchange_tooltip {
  /* width: 84px;
  height: 53px; */
  position: fixed;
  top: 36px;
  left: 416px;
  z-index: 999;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  pointer-events: none;
}

.exchange_tooltip_text {
  font-family: OpenSans-Bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  top: 30px;
  width: -webkit-fill-available;
  position: absolute;
}

.upload_csv_container {
  background: #F4F9FC;
  border-radius: 4px;
  display: flex;
  padding: 8px 12px;
  width: fit-content;
  margin: 16px 0px;
}

.upload_csv_img {
  height: 16px;
  width: 16px;
  margin-right: 6px;
}

.upload_csv_text {
  font-family: OpenSans-Bold;
  font-size: 14px;
  line-height: 16px;
  color: #1696EC;
  opacity: 0.8;
}

.preview_task_text {
  font-family: OpenSans-Bold;
  font-size: 14px;
  line-height: 16px;
  color: #1696EC;
  margin-bottom: -16px;
}

.preview_task_cont {
  margin-top: 28px;
}

.preview_dialog_container {
  background: #FFFFFF;
  border: 1px solid #E4ECEF;
  box-sizing: border-box;
  box-shadow: 0px 3px 10px rgb(0 0 0 / 20%);
  border-radius: 21px;
  width: 368px;
  height: 656px;
  max-width: 368px;
  max-height: 656px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  padding: 24px;
}

.preview_dialog_line {
  margin-left: -24px;
  width: calc(100% + 48px);
  height: 5px;
  background: #E4ECEF;
  margin-top: 24px;
}

.preview_dialog_title2 {
  font-size: 14px;
  margin-top: 24px;
}

.preview_task_img {
  height: 16px;
  width: 16px;
  margin-right: 8px;
}

.preview_task_btn {
  border: 1px solid #1696EC;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  line-height: 19px;
  font-family: OpenSans-SemiBold;
  padding: 18px 50px;
  color: #1696EC;
  margin-left: 24px;
}

.univ_csv_description {
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.7);
}

.csv_container_result {
  background: #F4F9FC;
  border-radius: 4px;
  padding: 24px 20px;
  margin-top: 16px;
}

.csv_error_text {
  font-family: OpenSans-Bold;
  font-size: 14px;
  line-height: 19px;
  color: #FF6262;
  opacity: 0.8;
  margin-bottom: 16px;
}

.img_click {
  color: #1696EC;
}

.csv_params {
  color: #000000;
}

.csv_item_container {
  background: #D3E6F1;

  border-radius: 34px;
  padding: 2px 6px;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  margin-bottom: 16px;
  margin-right: 10px;
  width: fit-content;
}

.proof_date_text {
  font-size: 12px;
  line-height: 28px;
  text-align: right;

  color: #000000;
  opacity: 0.5;
}

.csv_item {
  margin-right: 10px;

}

.csv_item_val {
  margin: 6px 0px -10px 0px;

}


.order_info_stats_filter {
  font-size: 14px;
  line-height: 28px;
  color: #000000;
}

.dates_info_cont {
  position: absolute;
  top: -24px;
  left: 0px;
}

.order_info_stats_date_cont {
  background: #F9FCFD;
  border: 1px solid #E7EEF2;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  padding: 4px 12px;
  margin-left: 16px;
  position: relative;
}

.order_info_stats_title {
  font-family: RobotoSlab-Bold;
  font-size: 21px;
  line-height: 28px;
  color: #112A3D;
}

.order_info_stats_filter_date {
  color: #1696EC;
  margin-left: 6px;
}

.order_info_stats_title_cont {
  display: flex;
}

.choose_drop_arrow {
  height: 10px;
  margin-bottom: 4px;
}

.choose_type_dropdown {
  top: 34px;
  width: calc(100% + 4px);
}

.add_keyword_input {
  background: white;
  width: -webkit-fill-available;
  margin-right: 16px;
  padding: 12px;
  border: 1px solid #C9D5DA;
  border-radius: 4px;
}

.choose_type_icon {
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  height: 28px;
  width: 28px;
  margin-right: 12px;
}

.type_icon_img {

  height: 28px;
  width: 28px;
}

.choose_type_text {
  margin-top: 4px;
}

.universal_input {

  color: #7f7f7f;
  height: -moz-fit-content;
  height: fit-content;
  background: #fff;
  border: 2px solid #e4ecef;
  border-radius: 4px;
  font-size: 11px;
  display: flex;
  padding: 7px 20px 12px 20px;
  /* width: calc(100% - 40px); */
  width: -webkit-fill-available;
  margin-right: 20px;
  position: relative;
  height: 48px;
  border: 1px solid #C9D5DA;

}

.universal_desc {
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 12px;
  color: #2F3D48;
}

.universal_review_input {
  height: 100px;
  margin-top: 30px;

}

.top_30 {
  margin-top: 30px;
}

.top_8 {
  margin-top: 8px;
}

.text_area_input {
  height: -webkit-fill-available;
}

.secs_text {
  position: absolute;
  right: 16px;
  margin-bottom: 0px;
  bottom: 18px;
}

.price_one_install_univer {
  width: calc(50% - 92px);
}

.top_12 {
  margin-top: 12px;
}

.universal_options {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.universal_options_text {
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  margin-left: 4px;
}

.left_16 {
  margin-left: 16px;
}

.universal_info_title {
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  opacity: 0.5;
}

.universal_info_pad {
  padding: 12px 20px 12px 20px;
}

.universal_info_desc {
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  color: #000000;
  margin-top: 16px;
}

.proof_container {
  background: rgba(150, 198, 240, 0.3);
  border: 1px solid #E7EEF2;
  box-sizing: border-box;
  border-radius: 0px 0px 4px 4px;
  padding: 24px 20px 24px 20px;
}

.universal_info_proof {
  font-family: OpenSans-Bold;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.pad_0 {
  padding: 0px;
  height: fit-content;
}

.proof_desc {
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  margin-top: 16px;
}

.top_32 {
  margin-top: 32px;
}

.url_universal {
  color: #1696EC;
  cursor: pointer;
  white-space: nowrap;
  overflow: scroll;
  text-overflow: ellipsis;
}

.url_universal:hover {
  opacity: 0.7;
}

.toolbar_info {
  display: flex;
  justify-content: space-between;
}

.toolbar_info_cont {
  display: block;
  width: fit-content;
}

.toolbar_title {
  display: flex;

}

.toolbar_title_text {
  cursor: pointer;
  transition: color 0.3s ease;
  line-height: 28px;
}

.toolbar_title_text:hover {
  opacity: 0.7;
}

.m_left_32 {
  margin-left: 32px;
  margin-right: 8px;
}

.toolbar_line_container {
  margin-top: 4px;
}

.toolbar_line {
  height: 3px;
  background: #1696EC;
  border-radius: 100px;
  width: 61px;
  transition: all 0.3s ease-in-out;
}

.transition_opacity {
  transition: opacity 300ms;
}

.proofs_container {
  margin-top: 24px;
  margin-bottom: 20px;
  width: -webkit-fill-available;
}

.buble_proofs {
  background: #FF6262;
  border-radius: 100px;
  font-size: 12px;
  color: white;
  height: 24px;
  width: 24px;
  text-align: center;
}

.top_3 {
  margin-top: 3px;
}

.waitin_review_title {
  display: flex;
  justify-content: space-between;
}

.accept_all_container {
  display: flex;
  cursor: pointer;
}

.accept_all_container:hover {
  opacity: 0.7;
}

.accept_text {
  color: #1696EC;
  margin-left: 8px;
  margin-top: 2px;
}

.proof_item_container {
  background: rgba(244, 249, 252, 0.5);
  border: 1px solid #E7EEF2;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 20px;
  width: 100%;
  margin-top: 16px;
}

.order_badge {
  position: absolute;
  top: -8px;
  right: -8px;
  border: 2px solid #FFFFFF;
}

.proof_avatar_cont {
  height: 24px;
  width: 24px;
  margin-right: 12px;
  border-radius: 100px;
}

.proof_avatar {
  height: 24px;
  width: 24px;
  border-radius: 100px;

}

.employer_name {
  font-size: 14px;
  line-height: 28px;
  color: #000000;
  font-family: OpenSans-Bold;
}

.employer_text {
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  margin-top: 10px;
  margin-bottom: 20px;
  max-height: 300px;
  overflow: hidden;
}

.accept_btn {
  background: #1696EC;
  border-radius: 4px;
  padding: 10px 16px 10px 16px;
  display: flex;
  transition: opacity 300ms;
  margin-right: 24px;
  cursor: pointer;
  height: fit-content;
  width: fit-content;
}

.send_to_do {
  font-size: 14px;
  line-height: 19px;
  color: #1696EC;

  margin-top: 11px;
}

.reject {
  font-size: 14px;
  line-height: 19px;
  color: #FF6262;

  margin-left: 24px;
  margin-top: 11px;
}

.accept_btn_text {
  color: white;
  margin-left: 8px;
}

.hover {
  transition: opacity 300ms;
  cursor: pointer;
}

.hover:hover {
  opacity: 0.7;
}

.send_to_container {
  background: #DFEEFB;
  border: 1px solid #E7EEF2;
  box-sizing: border-box;
  border-radius: 0px 0px 4px 4px;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -20px;
  width: calc(100% + 40px);
  padding: 16px 20px 24px 16px;
}

.proof_finished {
  background: #D7FCEC;
  border-radius: 0px 0px 4px 4px;
  padding: 16px 20px 16px 20px;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -20px;
  width: 100%;
  color: black;
}

.rejected_proof {
  background: #FFE4E3;
}

.to_do_proof {
  background: rgba(150, 198, 240, 0.3);
}

.rejection_comment {
  margin-top: 16px;
  font-family: OpenSans-Bold;
  white-space: nowrap;
  overflow: scroll;
  text-overflow: ellipsis;
}

.fix_employer {
  background: #FFFFFF;
  border: 2px solid #E4ECEF;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 8px 0px 12px 0px;
  width: 100%;
  padding: 10px 16px 10px 16px;
  color: black;
  border-width: 0px;
  font-size: 14px;
  border: 2px solid rgba(150, 198, 240, 0.3);
  transition: all 0.3s;
  font-size: 14px;
  line-height: 28px;
  max-height: 132px;
  height: fit-content;
  overflow: auto;
  min-height: 52px;
}

.fix_employer:focus {
  border: 2px solid #96C6F0;
}

.fix_employer[placeholder]:empty:before {
  content: attr(placeholder);
  font-size: 14px;
  line-height: 28px;
  display: flex;
  align-items: center;

  color: #000000;

  opacity: 0.5;
}

.desc_input_text {
  overflow: auto;
  height: auto;
}

.desc_input_text[placeholder]:empty:focus:before {
  content: "";
}

.desc_input_text[placeholder]:empty:before {
  content: attr(placeholder);
  font-size: 14px;
  line-height: 28px;
  display: flex;
  align-items: center;

  color: #000000;

  opacity: 0.3;
}

.desc_input_text[placeholder]:empty:focus:before {
  content: "";
}

.proof_filter {
  width: 160px;
  margin-bottom: 0px;
  opacity: 0;
  transition: all 0.3s;
  padding: 10px 16px 10px 0px;
  margin-top: -8px;
}

.universal_info_container {
  width: 66.6%;
  display: block;
  border-right: 1px solid #E7EEF2;
  padding-right: 32px;
  margin-top: 32px;

}

.left_universal {
  width: 60%;
}

.right_universal {
  width: 40%;
  border: none;
}

.rejection_bg {
  background: #FF6262;
}

.flex_land {
  display: flex;
}

.top_m_4 {
  margin-top: 4px;
}

.reject_left {
  margin-left: 0px;
}

.relative_picker {
  position: relative;
}

.edit_controls {
  background: rgba(228, 236, 239, 0.4);
  border: 1px solid #E4ECEF;
  box-sizing: border-box;
  border-radius: 4px 4px 0px 0px;
  padding: 8px 20px 8px 20px;
  display: flex;
  width: calc(100% - 4px);
  justify-content: space-between;
}

.width_error {
  font-size: 12px;
  margin-top: 4px;
  color: #FF6262;
}

.desc_row {
  width: calc(100% + 40px);
  margin-right: 20px;
}

.edit_input_controls {
  border-radius: 0px 0px 4px 4px;
  border-top: 0px;
  height: auto;
  min-height: 48px;
  width: calc(100% - 46px);
}

.new_border {
  border: 1px solid #C9D5DA;
}

.new_border_hover:hover {
  border: 1px solid #97c7ed
}

.m_left_8 {
  margin-left: 8px;
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
  color: rgba(0, 0, 0, 0);
}

.custom-file-input::before {
  content: '';
  display: inline-block;
  background: none;
  background-image: url("../pages_android/custom_views/custom_icons/image_pick.svg");
  border: 0px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  margin-top: 5px;
  margin-left: 8px;
  width: 40px;
  height: 28px;
  color: rgba(0, 0, 0, 0);

}

.custom-file-input:hover::before {
  border-color: black;
  color: rgba(0, 0, 0, 0);
}

.custom-file-input:active::before {
  color: rgba(0, 0, 0, 0);
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.universal_order_filters {
  display: flex;
  justify-content: space-between;
}

.new_univ_order {
  margin: 0px 24px 0px 0px;
  box-shadow: 0px 4px 15px rgba(246, 221, 0, 0.4);
  font-size: 14px;
  line-height: 200%;
}

.m_right_12 {
  margin-right: 12px;
  margin-top: 11px;
}

.fire_icon {
  height: 24px;
  margin-top: 16px;
  margin-left: 6px;
}

.mobile_create_order_container {
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.2);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 56px;
  display: none;
  background: white;
  z-index: 99;
}

.mobile_univ_btn {
  padding: 8px 24px 8px 24px;
  margin-top: 8px;
  background: #FFE141;
  box-shadow: 0px 4px 15px rgba(246, 221, 0, 0.4);
  border-radius: 8px;
  line-height: 24px;
}

.up_balance_universal {
  background: #FFF3C9;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  opacity: 0.8;
  padding: 10px 16px 10px 16px;
  margin: -36px -24px 40px -24px;
}

.univ_btn_top {
  margin-top: 18px;
}

.top_4_m {
  margin-top: 4px;
}

.up_balance_btn_univ {
  background: #1696EC;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;

  color: #FFFFFF;
  padding: 10px 19px 10px 19px;
  margin-top: 24px;
  text-align: center;

}

.icon_new {
  position: absolute;
  left: 24px;
  top: 18px;
}

.flag_icon {
  position: absolute;
  left: 37px;
  top: 15px;
}

.icon_new_alpha {
  opacity: 0.7;
}

.installs_limits_title {
  font-family: RobotoSlab-Bold;
  font-size: 21px;
  line-height: 28px;
  color: #2F3D48;
  margin-bottom: 30px;
}

.left_40 {
  margin-left: 40px;
}

.radio_limits {
  box-sizing: border-box;
  border-radius: 50px;
  height: 24px;
  width: 24px;
  cursor: pointer;
  transition: all 0.3s;
  border: 1px solid #C9D5DA;
}

.radio_inside {
  background: #FFE141;
  border-radius: 50px;
  height: 12px;
  width: 12px;
  margin: 5px;
}

.right_text {
  text-align: right;
}

.progress_orders_margin {
  margin: 80px 80px 60px 40px;
}

.input_tag {
  margin-right: 6px;
  margin-bottom: 3px;
}

.input_tag_close {
  margin-left: 2px;
}

.input_tag_container {
  background: #1696EC;
  border-radius: 34px;
  display: flex;
  color: white;
  font-size: 14px;
  padding: 4px 12px;
  margin-right: 8px;
  position: relative;
}

.input_tag_height_1 {
  height: 261px;
  flex-wrap: wrap;
  width: auto;
  margin-right: 0px;
  overflow-y: scroll;
}

.tag_height_1 {
  min-height: 28px;
  margin-bottom: 8px;
}

.input_tag_width {
  width: auto;
  height: 28px;
  min-width: 300px;
}

.flex_input {
  display: flex;
  flex-wrap: wrap;
  height: fit-content;
}

.flex_input::-webkit-scrollbar {
  display: none;
}

.search_words {
  width: 100%;
  display: block;
}

.botm_12 {
  margin-bottom: 12px;
  margin-right: 8px;
}

.botm_16 {
  margin-bottom: 16px;
}

.help_icon {
  height: 24px;
  width: 24px;
}

.search_words_left {
  margin-left: 20px;
  width: calc(100% - 60px);
}

.tags_right_container {
  background: rgba(228, 236, 239, 0.4);
  border: 1px solid #E7EEF2;
  box-sizing: border-box;
  border-radius: 4px;
  height: 289px;
  padding: 18px 20px;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.input_right_placeholder {
  font-size: 14px;
  line-height: 19px;
  color: #2F3D48;
  opacity: 0.5;
  text-align: center;
}

.searching_requests {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.not_found_icon {
  height: 120px;
  margin-left: 40px;
}

.text_left {
  text-align: left;
}

.pay_types_container {
  display: flex;
}

.exchange_text_pay {
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;

  color: #000000;

  opacity: 0.5;
  margin: 0px 0px 0px -8px;
}

.type_pay_picker {
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  margin: 24px 0px 30px 0px;
}



.type_pay_container {
  border: 1px solid #C9D5DA;
  box-sizing: border-box;
  border-radius: 4px;
  width: 120px;
  height: 122px;
  margin-bottom: 35px;
  margin-right: 48px;
  text-align: center;
}

.type_pay_img {
  height: 40px;
  width: 40px;

  margin: 12px auto;
}

.pay_checked {
  position: absolute;
  right: 36px;
  top: -12px;
  opacity: 0;
}

.type_pay_title {
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #000000;

}

.order_info {
  overflow: scroll;
  @media (max-width: 960px) {
    overflow: visible;
  }
}

@media (max-width: 560px) {
  .pay_types_container {
    display: block;
  }

  .pay_checked {
    position: absolute;
    right: -12px;
    top: -12px;
    opacity: 0;
  }

  .exchange_text_pay {
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;

    color: #000000;

    opacity: 0.5;
    margin: 0px 0px 0px 16px;
  }

  .type_pay_title {
    font-size: 14px;
    line-height: 19px;
    text-align: left;
    color: #000000;
    margin: auto 0;
  }



  .type_pay_img {
    height: 40px;
    width: 40px;

    margin: auto 11px auto 12px;
  }

  .type_pay_container {
    border: 1px solid #C9D5DA;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
    height: 48px;
    margin-bottom: 16px;
    margin-right: 20px;
    text-align: left;
    display: flex;
  }
}

@keyframes to_black {
  from {
    color: #1696EC;
  }

  to {
    color: black;
  }
}

@keyframes to_blue {
  from {
    color: black;
  }

  to {
    color: #1696EC;
  }
}

@media (max-width: 1400px) {
  .info_container {
    flex-wrap: wrap;

  }

  .info_props_container {
    width: 240px;
  }

  .info_installs {
    width: 200px;
  }

  .search_words_container {
    width: 360px;
  }

  .left_universal {
    width: 60%;
  }

  .right_universal {
    width: 40%;
  }
}

@media (max-width: 1268px) {
  .exchange_tooltip {

    position: fixed;
    top: 36px;
    left: 182px;
  }

  .main {
    width: 100%;
  }

  .info_props_container {
    width: 200px;
  }

  .info_installs {
    width: 200px;
  }

  .search_words_container {
    width: 360px;
  }

  .left_universal {
    width: 60%;
  }

  .right_universal {
    width: 40%;
  }
}


@media (max-width: 1024px) {
  .main {
    width: 100%;
  }

  .order_info_status_container {
    margin-left: 0px;
    margin-top: 12px;
  }

  .left_universal {
    width: 60%;
  }

  .right_universal {
    width: 40%;
  }
}

@media (max-width: 768px) {
  .exchange_tooltip {

    position: fixed;
    top: 36px;
    left: 182px;
  }

  .link_to_universal {
    padding: 16px;
    margin: 8px 8px -10px 8px;
  }

  .universal_input {
    margin-right: 0px;
  }

  .search_words_left {
    margin-left: 0px;
    width: -webkit-fill-available;
    margin-top: 30px;
  }

  .stats_title_text {
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    margin-bottom: 16px;
    margin-top: 24px;
    color: #2F3D48;
  }

  .mobile_flex {
    display: block;
  }

  .reject {
    margin-left: 0px;
  }

  .accept_btn {
    margin-bottom: 16px;
  }

  .toolbar_info {
    display: block;
  }

  .proof_filter {
    margin-top: 20px;
  }

  .not_found_icon {
    margin-left: 10px;
  }

  .progress_orders_margin {
    margin: 30px 0px 30px 0px;
  }

  .price_one_install_univer {
    width: calc(100% - 40px);
  }

  .input_margin_bot {
    margin-bottom: 30px;
  }

  .desc_row {
    width: calc(100% + 4px);
  }

  .up_balance_universal {
    background: #FFF3C9;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
    opacity: 0.8;
    padding: 10px 16px 10px 16px;
    margin: -36px -16px 20px -16px;
  }

  .info_installs {
    width: 100%;
    margin-left: 0px;
  }

  .tooltip_bg_mobile {
    top: 250px;
  }

  .mobile_create_order_container {
    display: block;
  }

  .new_univ_order {
    display: none;
  }

  .main {
    width: 100%;
  }

  .left_32 {
    margin-left: 0px;
  }

  .send_ref {
    margin-top: 0px;
  }

  .search_aso {
    width: 100%;
  }

  .flex_land {
    display: block;
  }

  .universal_info_container {
    width: 100%;
    margin-right: 0px;
    border: 0px;
  }

  .input_description_text {
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.7);
    margin-bottom: auto;
    position: initial;
    top: 0;
    transform: inherit;
  }

  .seo_top_container {
    width: 100%;
  }

  .keywords_cont_all {
    width: 100%;
  }

  .order_main {
    min-height: calc(100vh - 152px);

  }

  .finished_task_status {
    margin-top: 0px;
  }

  .info_container {
    display: block;
  }

  .search_words_container {
    width: 100%;
    min-width: auto;
  }

  .order_info_status_container {
    margin-left: 0px;
    margin-top: 12px;
  }

  .info_props_container {
    width: 100%;
    border: 0px;
    padding: 0px;
  }

  .orders_container {
    margin-top: 52px;
    margin-right: -24px;
  }

}

@media (max-width: 560px) {
  .order_info_stats_title_cont {
    display: block;
  }

  .order_info_stats_date_cont {
    margin: 16px 0px 0px 0px;
  }

  .order_container {
    width: 100%;
  }

  .order_info_status_container {
    margin-left: -96px;
    margin-top: 60px;
    display: block;
  }

  .info_title {
    font-size: 16px;
    line-height: 24px;
  }

  .pause_play_info {
    margin-left: 0px;
    margin-top: 16px;
  }

  .date_text_order_info {
    margin-left: 0px;
    margin-top: 10px;
    text-align: left;

  }

  .finished_task_status {
    margin-left: 0px;
    margin-top: 16px;
  }
}

.just_content_auto a {
  color: rgba(255, 255, 255, 0.7);
}

.review_line {
  height: 1px;
  background: #D1D8E4;
  width: 100%;
  margin-top: 30px;
}

.lds-ring-input div {
  box-sizing: border-box;
  display: block;
  width: 16px;
  height: 16px;
  position: absolute;
  margin: 4px;
  border: 2px solid #1696EC;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #1696EC transparent transparent transparent;

}

.lds-ring-input div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring-input div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring-input div:nth-child(3) {
  animation-delay: -0.15s;
}

.search_close_order_icon {
  position: absolute;
  right: 10px;
  top: 14px;
}

.idfa_item_container {
  display: flex;
  width: 100%;
  margin-top: 24px;
}

.idfa_image {
  height: 24px;
  width: 24px;
  border-radius: 100px;
  margin-right: 8px;
  background: #BBE3FC;
}

.idfa_title_cont {
  width: 254px;
  display: flex;
  justify-content: space-between;
  margin-top: 2px;
}

.idfa_text {
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.idfas_line_top {
  margin-top: 10px;
}

.idfa_title {
  font-family: OpenSans-Bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 8px;
}

.idfa_text_id {
  font-size: 12px;
  line-height: 20px;
  color: #000000;
  opacity: 0.5;
  margin-top: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 256px;
}

.idfa_count {
  font-size: 21px;
  line-height: 28px;
  color: #000000;
  opacity: 0.5;
  font-family: Roboto Slab;
  margin-left: 8px;
}

.idfas_list {
  height: 394px;
  overflow: scroll;
}

.idfas_btn {
  width: 160px;
  text-align: center;
  height: fit-content;
  background: #1696EC;
  border-radius: 4px;
  font-size: 14px;
  color: #FFFFFF;
  line-height: 19px;
  padding: 12px;
  font-family: OpenSans-Semibold;
  margin-top: 16px;
}

.idfas_btns {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.idfa_dnwload {
  margin-top: 12px;
}

.inside_review_row {
  margin-left: 40px;
  margin-right: 40px;
}

.review_banner {
  background: #F4F9FC;
  padding: 8px 20px;
  font-size: 14px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.8);
  margin: 0px 0px 12px 0px;
}

.row_review_with {
  margin-bottom: 12px;
}

.display_limits {
  margin-left: 40px;
  margin-top: 40px;
}

.banner_instarlike {
  min-height: 40px;
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.top_24_banner {
  margin-top: 24px;
}

.instarlike_banner_text {
  margin: 11px 16px 9px 16px;
}

.desctop_payments {
  display: block;
}

.mobile_payments {
  display: none;
}

.remove_order_request {
  height: 16px;
  width: 16px;
  margin-left: 4px;
  margin-top: 7px;
  margin-right: 16px;
}

.info_add_request {
  background: #1696EC;
  border-radius: 4px;
  display: flex;
  position: relative;
  margin-bottom: 16px;
}

.info_add_request_img {
  width: 16px;
  height: 16px;
  margin: 8px;
}

.info_add_request_text {
  font-size: 14px;
  line-height: 28px;
  color: #FFFFFF;
  margin-top: 2px;
}

@media (max-width: 560px) {
  .instarlike_banner_text {
    margin: 16px 12px 16px 8px;
  }

  .desctop_payments {
    display: none;
  }

  .instarlike_img {
    margin-left: 12px;
  }

  .mobile_payments {
    display: block;
    position: relative;
    left: 50%;
    max-width: calc(100% - 72px);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
}

.btn_pay_d {
  margin-top: 40px;
}

.menu_icon_social {
  padding-left: 24px;
}

.menu_icon_s_container {
  padding-top: 16px;
}

.screen_row {
  margin-top: 24px;
}

.screen_row_descr {
  margin-right: 32px;
}

.screen_descr_text {
  position: relative;
}

.screen_title {
  font-size: 14px;
  line-height: 16px;
  color: #112A3D;
  opacity: 0.8;
}

.pos_relative {
  position: relative;
}

.example-enter {
  opacity: 0;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0;
  transition: opacity 0ms ease-in;
}

.asdsad-enter {
  max-height: 0;
  overflow: hidden;
}

.asdsad-enter-active {
  max-height: initial;
  transition: max-height .3s ease;
}

.asdsad-leave {
  max-height: initial;
}

.asdsad-leave-active {
  max-height: 0;
  transition: max-height .3s ease;
  overflow: hidden;
}

.show {
  display: block;
}

.sdk_line {
  margin: 30px -24px;
}

.sdk_description_title {
  font-size: 21px;
  line-height: 28px;
  margin-left: 8px;
}

.sdk_number_container {
  background: #F4F9FC;
  border: 1px solid #E4ECEF;
  box-sizing: border-box;
  border-radius: 8px;
  position: relative;
  height: 32px;
  width: 32px;
  min-width: 32px;
  min-height: 32px;
  margin-right: 16px;
}

.sdk_center {
  margin: 0;
  position: relative;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.sdk_number_text {
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #112A3D;
  font-family: OpenSans-Bold;
}

.vertical_line {
  background: #C9D5DA;
  width: 1px;
  margin-right: 20px;
  margin-left: 20px;

}

.full_width {
  width: -webkit-fill-available;
}

.input_name_width {
  width: calc((100vw - 290px - 204px)/2 - 1px);
}

.input_price_width {
  width: 75px;
}

.top_6_sdk {
  margin-top: 6px;
}

.top_12_sdk {
  margin-top: 12px;
}

.sdk_descr_height {
  height: 142px;
}

.sdk_decr_input {
  height: 116px;
  overflow: auto;
}

.sdk_description_d {
  top: 4px;
  transform: inherit;
}

.sdk_add_task {
  background: #F4F9FC;
  width: -webkit-fill-available;
  padding: 16px 16px 18px 16px;
  color: rgba(22, 150, 236, 0.8);
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  margin-top: -14px;
}

.delete_sdk_task {
  font-size: 14px;
  line-height: 28px;
  color: #FF6262;
  margin-left: 8px;
}

.delete_sdk_task_cont {
  position: absolute;
  bottom: 0;
  left: 0;
}

.sdk_change_orders {
  font-size: 14px;
  line-height: 19px;
  color: #1696EC;
}

.sdk_order_cont {
  justify-content: space-between;
}

.sdk_enable_sdk_text {
  font-size: 12px;
  line-height: 16px;
  color: #FFFFFF;
  margin: 6px 10px 6px 12px;
}

.sdk_enable_sdk_icon {
  margin: 6px 12px 6px 0px;
  height: 16px;
  width: 16px;
}

.sdk_enable_bg_red {
  background: #FF6262;
  border-radius: 30px;
  width: fit-content;
}

.sdk_controlls {
  margin: 9px 0px 0px 0px;
}

.sdk_title_controll {
  font-size: 14px;
  line-height: 28px;
  color: #1696EC;
}

.sdk_title_controll_margin {
  margin-right: 24px;
}

.sdk_title_controll_enabled {
  color: #112A3D;
}

.hidden_list {
  opacity: 0;
  pointer-events: none;
  height: 0px;
  max-height: 0px;
  overflow: hidden;
}

.sdk_info_desc_container {
  background: #F4F9FC;
  padding: 16px 24px;
  display: flex;
  margin: 16px -24px 32px -24px;
  width: -webkit-fill-available;
}

.sdk_info_item {
  border-radius: 8px;
  background: white;
  margin-right: 16px;
  padding: 16px 24px;
  display: flex;
  width: 320px;
  position: relative;
}

.sdk_item_number {
  height: 24px;
  width: 24px;
  min-width: 24px;
  min-height: 24px;
  border-radius: 14px;
}

.sdk_number_regular {
  font-family: OpenSans-Regular;
}

.sdk_item_title {
  font-size: 14px;
  line-height: 16px;
  color: #112A3D;
  margin-top: 4px;
}

.sdk_waiting {
  font-size: 12px;
  line-height: 16px;
  color: #FF6262;
  position: absolute;
  right: 24px;
  top: 16px;
}

.sdk_item_desc {
  font-size: 12px;
  line-height: 16px;
  color: #112A3D;
  opacity: 0.8;
  margin-top: 12px;
}

.sdk_instruction_cont {
  max-width: 50%;
}

.sdk_instruct_text {
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.sdk_code_cont {
  background: #F4F9FC;
  border: 1px solid #C9D5DA;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
  width: -webkit-fill-available;
  padding: 16px 24px 20px 20px;
  margin: 12px 0px 32px 0px;
}

.sdk_copy_code {
  height: 20px;
  min-width: 20px;
  width: 20px;
  min-height: 20px;
  position: absolute;
  top: 16px;
  right: 10px;
}

.normal_text_14 {
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  margin-top: 2px;
  margin-right: 16px;
  white-space: pre-wrap;
}

.sdk_code_id {
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  opacity: 0.5;
  margin-bottom: 3px;
}

.sdk_task_item_title {
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  font-family: RobotoSlab-Bold;
}

.sdk_task_price {
  opacity: 0.5;
  margin-left: 4px;
}

.top_line_0 {
  margin-top: 0px;
}

.sdk_task_item_desc {
  font-size: 14px;
  line-height: 150%;
  color: #000000;
  margin-top: 8px
}

.sdk_enabled_img {
  min-width: 24px;
  width: 24px;
  min-height: 24px;
  height: 24px;
  margin-right: 12px;
}

.sdk_enabled_text_green {
  font-size: 12px;
  line-height: 16px;
  color: #0FD669;
}

.sdk_copied {
  background: rgba(17, 42, 61, 0.7);
  border-radius: 4px;
  font-size: 12px;
  line-height: 20px;
  color: #FFFFFF;
  padding: 10px 24px;
  opacity: 0;
  position: fixed;
  bottom: -100px;
  transition: all 0.3s ease-in-out;
  left: 50%;
  z-index: 9999999;
  -ms-transform: translate(-50%);
  transform: translate(-50%);
}

.sdk_create_installs {
  margin-left: 48px;
}

.sdk_auto_width {
  width: auto;
}

.order_info_mob {
  display: flex;
}

.publish_app {
  padding-bottom: 40px;
}

.download_app_btn_mobile {
  background: #1696EC;
  border-radius: 4px;
  color: white;
  padding: 10px 60px 10px 60px;
  font-family: OpenSans-Semibold;
  font-size: 14px;
  line-height: 19px;
  margin-top: 8px;
  margin-bottom: 24px;
  text-align: center;
  display: none;
}

.download_app_btn {
  font-size: 14px;
  line-height: 20px;
  color: #1696EC;
  margin-top: 8px;
  margin-bottom: 24px;

}

.sdk_stats_item {
  margin-left: 32px;

}

.count_from {
  font-size: 12px;
  line-height: 150%;
  color: #000000;
  opacity: 0.5;
}
.min_time_review_descr{
  @media screen and (min-width: 768px) {
    margin-top: 12px;
  }
}
.sdk_count_circle {
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #000000;
}

.help_sdk {
  margin-left: 8px;
  margin-top: 2px;
}
.order_info_block_reason{
  color: rgb(255, 98, 98);
  margin: 16px 0 0 0;
}
.asdfasg {
  font-size: 17px;
  font-family: 'OpenSans-Semibold';
  margin-top: 16px;
}

.sdk_reorder_cont {
  display: flex;
}

.switch_container_limit {
  margin-right: 36px;
}

@media (max-width: 1100px) {
  .sdk_instruction_cont {
    max-width: -webkit-fill-available;
  }
}

@media (max-width: 769px) {

  .sdk_info_desc_container {
    display: block;
    margin: 16px -24px 32px -24px;

    padding: 16px 24px 0px 24px;
  }

  .sdk_instruction_cont {
    max-width: -webkit-fill-available;
  }

  .sdk_info_item {
    margin-bottom: 16px;
    width: auto;
    margin-right: 0px;
  }

  .close_order_icon {
    right: 8px;
    top: 8px;
  }
}

.filter_dropdown_countries {
  margin: 14px 0px 0px -22px;
  top: auto;

}

@media (max-width: 561px) {
  .order_info_mob {
    display: block;
  }

  .download_app_btn_mobile {
    display: block;
  }

  .download_app_btn {
    display: none;
  }

  .delete_sdk_task_cont {
    position: relative;
    margin-top: 16px;
  }

  .sdk_reorder_cont {
    display: block;
  }

  .sdk_change_orders {
    margin: 8px 0px 0px 32px;
  }

  .filter_dropdown {
    width: calc(100vw - 48px);
  }

  .filter_dropdown_countries {
    width: calc(100vw - 48px);
  }

  /* .main_container{
    overflow-x: hidden;
  }
  body{
    overflow-x: hidden;
  } */
}

.min_price_univ {
  color: rgb(255, 98, 98);
  font-size: 12px;
  margin-top: 10px;
}

.earn_pad {
  padding: 30px 16px 30px 16px;
}

.text_earn_p {
  font-size: 10px;
  line-height: 120%;
  color: #000000;
  margin-top: 8px;
}

.get_mon {
  background: #1696EC;
  border-radius: 4px;
  font-size: 14px;
  line-height: 19px;
  color: #FFFFFF;
  font-family: OpenSans-SemiBold;
  padding: 25px;
  margin: 16px -16px -30px -16px;
}

.get_money_d_title {
  font-size: 21px;
  line-height: 29px;
  color: #000000;
}

.country_picker {
  display: block;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.country_picker_title {
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  opacity: 0.5;
}

.country_picker_text {
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  margin-top: 8px;
}

.country_icon {
  height: 16px;
  width: 16px;
  margin-top: 8px;
}

.country_drop_down_icon {
  height: 16px;
  width: 16px;
}

.country_picker_text_drop {
  font-size: 14px;
  line-height: 19px;
  color: #000000;

}

.countries_picker_container:hover {
  border: 2px solid #97c7ed
}

.toolbar_line_doc {
  width: 54px;
  transition: all 0.3s ease-in-out;
}

.doc_container {
  transition: all 0.3s ease-in-out;
  opacity: 1;
}

.sdk_mb_16 {
  margin-bottom: 16px;
}

.proof_list_title {
  font-weight: 900;
  font-family: RobotoSlab-Bold;
  font-size: 21px;
  line-height: 28px;
  color: #000000;

}

.mass_answer_btn {
  margin-left: 32px;
}

.mass_answe_btns_container {
  border: 1px solid #E7EEF2;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  display: flex;
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 8px 21px;
}

.mass_answe_btns_container_toggled {
  justify-content: space-between;
}

.mass_answe_decline_title {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  padding: 3px 0px;
}

.mass_reject_bad {
  margin-left: 0;
  margin-right: 24px;
}

.no_wrap_proof_name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.input_ml_create {
  margin-left: 16px;
}

.color_cancel_dialog {
  color: var(--lk-blue);
}

.save_lang_dialog {
  border-radius: 4px;
  padding: 10px 24px;
  background: var(--lk-blue);
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: var(--white);
  margin-left: 12px;
}

.choose_lang_row_text {
  font-weight: 400;
  font-size: 14px;
  line-height: 114%;
  color: var(--black);
  margin: auto 16px;
}

.choose_lang_row {
  border-radius: 8px;
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
}

.menu_lang_flag {
  position: absolute;
  right: 12px;
  top: 36px;
  width: 24px;
  height: 24px;

  @media (max-width: 768px) {
    display: none;
  }
}

.menu_lang_flag_mobile {

  width: 24px;
  height: 24px;
  display: none;
  margin: 16px 0 0 24px;

  @media (max-width: 768px) {
    display: block;
  }
}