.countries_search_input::placeholder{
    color: rgba(0, 0, 0, 0.5);
}
.countries_search_input{
    background: none;
    width: 100%;
 
    text-align: left;
    padding-top: 5px;
    font-size: 14px;
    line-height: 19px;
    font-family: OpenSans-Regular;
    color: black;
}
.countries_input_arrow{
    margin: auto;
}
.countries_input_line{
    height: 40px;
    width: 1px;
    margin: -2px 20px;
    background: #C9D5DA;
}
.countries_picked_contaner{
    display: flex;
    flex-wrap: wrap;
    margin-top: 24px;
}
.up_to_max_installs_menu {
    margin-left: 4px;
    font-size: 14px;
    color: #4299E1;
    font-family: OpenSans-Regular;
    font-size: 14px;
    line-height: 140%;

}
.up_to_max_installs{
    border-radius: 2px;
    padding: 2px 8px;
    width: fit-content;
    height: fit-content;
    background: var(--lk-blue);
    font-family: OpenSans-SemiBold;
    font-size: 12px;
    line-height: 140%;
    color: var(--white);
    margin: 0px 0 30px 0;
}
.country_tag{
    display: flex;
    background: #96C6F04D;
    font-family: OpenSans-Regular;
    font-size: 14px;
    font-weight: 600;
    line-height: 19.07px;
    text-align: left;
    padding: 4px 12px;
    color: #112A3D;
    margin-right: 8px;
    margin-bottom: 8px;
    border-radius: 24px;

}
.country_toggler{
    height: 48px;
    width: 48px;
    position: absolute;
    right: -20px;
    top: -4px;
    z-index: 13;
}
.country_toggler:hover{
    cursor: pointer;
}
.country_tag div{
    margin-right: 10px;
}