.filter_dropdown_analize{
  padding: 0px;
  display: block;
  position: absolute;
  z-index: 9999999;
  max-height: 428px;
  overflow-y: auto;
  padding-top: 6px;
  padding-bottom: 6px;
  cursor: auto;
  margin: 36px 0px 0px -2px;
  top: -36px;
  left: -305px;
  width: 312px;
  background: #FFFFFF;
  border: 1px solid #D6DBE3;
  box-sizing: border-box;
  box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  max-height: 288px;
  padding : 4px 0px 4px 0px;
  }
  .tooltip_bg_analize{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -9;
  }
  .filter_item{
    padding: 12px;
    display: flex;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  }
  .filter_item:hover{
    background: rgba(227, 240, 255, 0.5)
  }
  .filter_rate_text{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    width: 12px;
    color: #113946;
    margin-top: 1px;
  }
.add_tag_cointainer{
  background: #FFFFFF;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  width: 656px;
  height: 632px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 99;
  padding: 32px;
}

.add_tag_bg{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: rgba(17, 42, 61, 0.7)
}
.close_add_tag{
  position: absolute;
  right: 16px;
  top: 16px;
  height: 24px;
  width: 24px;
}
.add_tag_title{
  font-weight: 500;
  font-size: 28px;
  line-height: 33px;
  color: #000000;
  margin-bottom: 24px;
}
.team_picker{
  background: #F2F3F6;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #3978F1;
  padding: 12px 24px;
}
.left_team{
  border-radius: 110px 0px 0px 110px;
}
.right_team{
  border-radius: 0px 110px 110px 0px;
  padding-left: 36px;
  padding-right: 36px;
}
.active_team{
  background: #113946;
  color: #FFFFFF;
  
}
.tag_picker_title{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #113946;
  margin-top: 4px;
  
}
.tag_picker_container:hover, .tag_picker_container:focus{
  border: 2px solid #3978F1;
  padding: 13px 15px;
}
.tag_picker_container{
  border: 1px solid #D6DBE3;
  box-sizing: border-box;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  width: 312px;
  padding: 14px 16px;
  cursor: pointer;
  transition: all 0.3s ease-in;
  margin-right: 32px;
  height: fit-content;
}
.tag_picker_active{
  border: 2px solid #3978F1;
  padding: 13px 15px;
}
.arrow_tags{
  transition: all 0.3s ease-in;
}
.arrow_tags_active{
  transform: rotate(180deg)
}
.tags_list{
  width: 312px;
  height: 296px;
  background: #FFFFFF;
  border: 1px solid #D6DBE3;
  box-sizing: border-box;
  box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  margin-top: 32px;
  display: block;
  max-height: 296px;
  overflow: scroll;
  transition: all 0.3s ease-in;
  opacity: 0;
  pointer-events: none;
  z-index: 999;
}
.add_tag_item{
  padding: 16px 16px 13px 16px;
  cursor: pointer;
  transition: all 0.3s ease-in;
  display: flex;
}
.add_tag_item:hover{
  background: rgba(227, 240, 255, 0.5)
}
.add_tag_item_text{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #1696EC;
  margin-left: 8px;
  margin-top: 4px;
}
.tag{
  padding: 12px 16px;
  cursor: pointer;
  transition: all 0.3s ease-in;
  display: flex;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #113946;
  display: flex;
  justify-content: space-between;
}
.tag:hover{
  background: rgba(227, 240, 255, 0.5)
}
.add_tags_btns{
  display: flex;
  position: absolute;
  bottom: 48px;
  left: 32px;
}
.add_tag_btn{
  background: #079D4D;
  border-radius: 12px;
  padding: 15px 33px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;

  color: #FFFFFF;
}
.add_tag_cancel{
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  padding: 15px 33px;
  color: #113946;
}
.man_img{
  position: absolute;
  top: 268px;
  left: 262px;
  transition: all 0.3s ease-in-out;
  opacity: 1;
}
.old_tag_container{
  display: block;
  transition: all 0.3s ease-in-out;
  opacity: 1;
  pointer-events: all;

}
.new_tag_container{
  display: flex;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  pointer-events: none;
  top : 241px;
  left: 32px;

}
.new_tag_cont{
  transition: all 0.3s ease-in-out;
  opacity: 0;
  pointer-events: none;
  display: block;
}
.input_new_tag{
  border: 1px solid #D6DBE3;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 14px 16px;
  font-size: 14px;
  line-height: 16px;
  color: #113946;
  width: 312px;
  margin-bottom: 32px;
  
}
.input_new_tag:focus, .input_new_tag:active{
  border: 1px solid #D6DBE3;
  box-sizing: border-box;
  border-radius: 12px;
}
.input_new_tag::placeholder{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #113946;
  opacity: 0.5;
}
.keywords_title{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #113946;
  margin-bottom: 10px;
}
.keywords_input{
  border: 1px solid #D6DBE3;
  box-sizing: border-box;
  border-radius: 12px;
  width: 100%;
  height: 127px;
  padding: 16px 8px 16px 16px;
  transition: all 0.3s ease-in-out;
}


.m_right_32{
  margin-right: 32px;
}
.flex_input{
  display: flex;
  flex-wrap: wrap;
  height: fit-content;
  overflow: scroll;
  max-height: 95px;
}
.tag_height{
  height: fit-content;
  margin-bottom: 12px;
  background: #E3F0FF;
  border-radius: 110px;
  padding: 4px 12px;
  width: fit-content;
  display: flex;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.8);
  margin-right: 8px;
}

.input_text_search_analize{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  height: 18px;
  color: #113946;
  margin-top: 2px;
  width: 100%;

}
.happy_icon{
  margin-left: 230px;
  margin-top: 40px;
}
.input_text_search_analize::placeholder{
  font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 16px;
color: #113946;
opacity: 0.5;
}
.input_text_search_analize:focus .keywords_input{
  width: calc(100% - 2px);
  height: 125px;
  border: 2px solid #96C6F0;
}
.x_icon{
  margin-left: 4px;
}
.max_flex_input{
  max-height: initial;
}
.new_name_tag_container{
  background: #FFFFFF;
  border: 1px solid #E4ECEF;
  box-sizing: border-box;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  width: 256px;
  height: fit-content;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 99;
  padding: 24px;
}
.new_tag_name_title{
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 8px;
}
.input_new_tag_name{
  border: 1px solid #C9D5DA;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  padding: 10px 8px;
  height: 40px;
  margin-bottom: 16px;
  font-size: 14px;
}
.input_new_tag_name::placeholder{
  color: rgba(0, 0, 0, 0.5);
}
.create_new_tag_btn{
  background: #1696EC;
  border-radius: 4px;
  padding: 10px 19px;
  height: fit-content;
  color: #FFFFFF;
  font-family: OpenSans-Semibold;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
}
.cancel_new_tag_btn{
  margin-top: 8px;
  background: white;
  border-radius: 4px;
  padding: 10px 19px;
  height: fit-content;
  color: #1696EC;
  font-family: OpenSans-Semibold;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
}
.new_tag_name_error{
  width: calc(100% + 2px);
  background: #FF6262;
  border-radius: 0px 0px 4px 4px;
  padding: 14px 24px;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
  margin-left: -25px;
  margin-bottom: -25px;
}
.tag_list_container{
  background: #FFFFFF;
  border: 1px solid #E4ECEF;
  box-sizing: border-box;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  width: 320px;
  height: 529px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 99;
  padding: 16px;
}
.tag_list_title{
  font-family: RobotoSlab-Bold;
  font-size: 21px;
  line-height: 28px;
  color: #000000;

}
.tag_list_line{
  margin-left: -16px;
  width: calc(100% + 32px);
  height: 1px;
  background: #E4ECEF;

}
.tag_list_line_top{
  margin-top: 10px;
  margin-bottom: 24px;
}
.tag_list_btn{
  width: 160px;
  text-align: center;
  height: fit-content;
  background: #1696EC;
  border-radius: 4px;
  font-size: 14px;
  color: #FFFFFF;line-height: 19px;
  padding: 12px;
  font-family: OpenSans-Semibold;
  margin-left: 48px;
  margin-top: 16px;
}
.tags_list_cont{
  height: 345px;
  overflow: scroll;
}
.tag_list_item_cont{
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 12px 0px;
}
.tags_list_item_text{
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}
.trash_tag_list{
  margin-left: 15px;
}