.error_bg {
    display: block;
     height: 100%;
     width: 100%;
     top: 0;
     position: fixed;
     background: rgba(17, 42, 61, 0.7);
     z-index: 999999;
     left: 0;
     
 }
 .close_icon_error{
   cursor : pointer;
   height : 25px;
   width : 25px;
   margin-right : 8px;
   margin-top : 8px;
   right : 0;
   top: 0;
   position : absolute
 }
 .error_btn{
     text-transform: uppercase;
     font-weight: 700;
    
     cursor: pointer;
     padding: 12px;
     color: #33C6CC;
     font-size: 14px;
    line-height: 17px;
     text-align: right;
 }
 .sdk_close_btn{
   margin-left: 12px;
   text-transform: unset;
   border-radius: 4px;
}
 .error_btn:hover{
     background: rgba(0, 0, 0, 0.03);
 }
 .dialog_animation-enter {
    opacity: 0;
  }
  
  .dialog_animation-enter.dialog_animation-enter-active {
    opacity: 1;
    transition: opacity 100ms ease-in;
  }
  
  .dialog_animation-leave {
    opacity: 1;
  }
  
  .dialog_animation-leave.dialog_animation-leave-active {
    opacity: 0;
    transition: opacity 100ms ease-in;
  }
 .error_banner_container{
     display: block;
     position: fixed;
     left: 50%;
     top: 50%;
     padding: 48px 32px 48px 32px;
     height: fit-content;
     width: 30%;
     -webkit-transform: translate(-50%, -50%);
     transform: translate(-50%, -50%);
     border-radius: 8px;
     background: #fff;
     box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);
     max-width: 480px;
 }
 .sdk_reorder_container{
     display: block;
     position: fixed;
     
     padding: 48px 32px 48px 32px;
     height: fit-content;
     width: 30%;
    
     border-radius: 8px;
     background: #fff;
     box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);
     max-width: 340px;
     width: 340px;
     left: calc(50% - 170px);
 }
 @media (max-width: 768px) {
    .error_banner_container{
        width: 75%;
    }
}
.image_dialog{
    display: block;
    position: fixed;
    left: 50%;
    top: 50%;
    /* padding: 48px 32px 48px 32px; */
    height: -moz-fit-content;
    height: fit-content;
    /* width: 30%; */
    transform: translate(-50%, -50%);
    max-width: 50%;
}
.image_dialog_img{
    max-height: calc(100vh - 200px);
    /* width: auto; */
    max-width: 100%;
    object-fit: cover;
}
.dialog_btns{
    display: flex;
    justify-content: flex-end;
}
.dialog_btns_sdk{
    display: flex;
    justify-content: flex-start;
}
 .error_title{
    font-size: 22px;
    line-height: 20px;
    /* identical to box height, or 91% */
    text-align: center;
    font-weight: 900;
    margin-bottom: 24px;
    text-align: center;
    
    color: #000000;
    
    opacity: 0.9;
 }
 .error_text{
    font-size: 12px;
    line-height: 20px;
    color: #000000;
    opacity: 0.7;
 }
.close_icon{
   cursor : pointer;
   height : 25px;
   width : 25px;
   margin-right : 8px;
   margin-top : 8px;
   right : 0;
   position : absolute
}
.pic_email{
    width: 126px;margin-top : 60px;
}
.padding_16{
    padding: 16px;
    
}
.custom_task_name{
    font-size: 14px;
    line-height: 20px;
    color: rgba(0,0,0,.7);
    margin-left: 16px;
}
.custom_money_text{
    width: fit-content;
    margin-left: 16px;
}
.top_price_0{
    margin-top: 0;
}
.custom_task_description{
    margin-top: 40px;
}
.wrap_text_dialog{
    overflow: scroll;
    text-overflow: ellipsis;
}
.overflow_preview_dialog{
    overflow-y: overlay;
    max-height: 320px;
    padding: 24px 0px;
}

.custom_task_description{
    margin-top: 40px;
}
.is_need_screen_text{
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    margin-top: 13px;
    
    color: #000000;
}
.custom_task_review{
   background: #E3F2FF;
   padding: 24px 16px 14px 16px;
   font-size: 14px;
   line-height: 20px;
   color: #000000;
   margin-top: 16px;
   
}
.custom_task_review_title{
    font-family: OpenSans-Bold; 
   margin-bottom: 16px;
}
.center_hor_container{
    margin: 0;
        position: absolute;
        left: 50%;
        -ms-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
        width: max-content;
}
.custom_task_earning{
    font-size: 12px;
    margin-top: 16px;
}
.show_more_variants{
    font-family: OpenSans-SemiBold; 
    font-size: 14px;
    line-height: 19px;
    color: #1696EC;
    padding: 10px 24px;
    margin-top: 16px;
}
.divider_line_proff{
    background:  rgba(195, 212, 226, 0.5);
    height: 1px;
    width: 100%;
}
.timer_text{
    background: #FCF5C6;
    padding: 10px 16px 10px 32px;
    font-size: 14px;
    line-height: 20px;
    color: #000000; 
    margin-top: -8px;
  }
.open_support_btn {
      
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;
    color: black;
    background: #F5DB31;
    border-radius: 4px;
    padding-bottom: 12px;
    padding-top: 12px;
    width: 100%;
}
.open_support_btn:active{
    background: #FFEB69;
}
.start_custom_task_btn{
    margin: 0px 16px 16px 16px;
    width: auto;
}
.money_text{
    background: #6DCEAE;
    border-radius: 4px;
    font-size: 14px;
    text-align: center;
    color: #FFFFFF;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-top: 10px;
    margin-right: 16px;
    font-family: OpenSans-SemiBold;    
 }
 .money_text_custom{
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
 }
.custom_icon_container{
    width: 56px;
    height: 56px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}
.custom_icon{
   width: 56px;
   height: 56px;
   border-radius: 8px;
}
.dialog-close{
	position: absolute;
	right: 30px;
	top: 26px;
	width: 25px;
	
	cursor: pointer;
	outline: none;
}
.dialog-close:hover {
	opacity: 0.7;
}
.send_to_mail{
    text-align: center;font-size: 20px;
line-height: 29px; color : black; margin: 30px;
}
.pic_email_container{
    display: flex; width : 100%; justify-content: center;
}
.CircularProgressbar{
   
    width: 120px;
    vertical-align: middle;
    /* margin-left: auto; */
    height: 120px;
    position: absolute;
    top: 40px;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}
.circle_progress_container{
    width: 120px;
    vertical-align: middle;
    /* margin-left: auto; */
    height: 120px;
    position: absolute;
    top: 0;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}
.sdk_circle_progress_container{
    width: 80px;
    margin-top: -30px;
    margin-left: 10px;
    position: relative;
}
.cicle_progress_text{
    display: block;
    text-align: center;
    position: absolute;
    top: 70px;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}
.top_text{
    font-size: 24px;
}
.forgot_pass_input{
    
    border: 1px solid #ddd;
    font-size: 14px;
    padding-left: 20px;
    padding-right: 20px;
    transition: all 0.1s linear;
    margin-bottom: 20px;
    border-radius: 4px;
    outline: none;
    box-shadow: none;
    box-sizing: border-box;

    height: 56px; margin-top: 20px;
    width: -webkit-fill-available;
}
.forgot_pass_send{
    margin-left: 15px;outline: none;
}
.delete_order_text{
    text-align: left;
    font-size: 14px;
    line-height: 16px;
    color: black;
    margin-bottom: 24px;
}
.delete_order_title{
    font-family: RobotoSlab-Bold;
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 28px;
    margin-bottom: 24px;
    color: #000000;

}
.btn_red{
    background: #FF6262;
    border-radius: 4px;
    padding: 10px 24px 10px 24px;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: white;
    cursor: pointer;
}
.btn_red:hover{
    opacity: 0.7;
}
.color_cancel{
   color: #1696EC;
   margin-right: 8px;
}
.sdk_cicle_top{
    top: 76px;
}
.sdk_money_btn{
    background: #1696EC;
    border-radius: 4px;
    width: fit-content;
  }
.sdk_money_text{
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    margin-bottom: 32px;
}
.sdk_dialog_container{
    width: 340px;
    padding: 24px;
}
.reorder_sdk_container{
    display: flex;
    margin: 8px 0px;
    justify-content: space-between;
    position: relative;
}
.sdk_reorder_text{
    margin: 0;
  position: relative;
  top: calc(50% + 4px);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 14px;
    line-height: 20px;
    color: #000000;

}
.sdk_cancel_btn{
    font-size: 14px;
    line-height: 19px;
    color: #1696EC;
    margin-left: 24px;
    margin-top: 10px;
}
.sdk_reorder_buttons{
    margin-top: 12px;
    display: flex;
}
.sdk_reorder_title{
    font-family: RobotoSlab-Bold;
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 28px;
    color: #000000;
}
.sdk_reorder_line{
    height: 1px;
    background: #E4ECEF;
    margin: 0px -16px 0px -16px;
    width: calc(100% + 32px);
}
.sdk_reorder_line_title{
   margin-top: 12px;
}
.sdk_reorder_dialog_container{
    padding: 16px 16px 12px 16px;
    min-height: 500px;
    top: calc(50vh - 250px);
}
.sdk_reorder_bottom{
    position: absolute;
    height: 64px;
    bottom: 0;
    width: 100%;
}
.sdk_full_w{
    width: 100%;
}
.sdk_reorder_img{
    height: 16px;
    max-height: 16px;
    width: 16px;
    max-width: 16px;
}
.sdk_inner_list{
    max-height: 380px;
    overflow: auto;
    height: 380px;
    padding-top: 16px;
}
.flex_m{
    display: flex;
}
@media (max-width: 561px) {
    .sdk_dialog_container{
        width: calc(100vw - 96px);
    }
    .sdk_reorder_container{
        left : 24px;
        width: calc(100vw - 80px);
        max-width: calc(100vw - 80px);
    }
    .preview_dialog_container{
        width: calc(100vw - 32px);
    }
}
@media (max-width: 769px) {
    .preview_task_btn{
        width: fit-content;
        margin: 24px 0 0 0;
    }
    .flex_m{
        display: block;
    }
}
.get_m_pad{
    padding: 36px 24px 12px 24px;
  }
.get_m_phone{
    font-size: 14px;
    line-height: 19px;
    color: #000000;
    opacity: 0.5;
    margin: 32px 0px 12px 0px;
}
.get_m_input{
    border: 1px solid #C9D5DA;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 14px;
    line-height: 19px;
    color: black;
    padding: 18px;
    margin-bottom: 12px;
    width: 100%;
}
.get_m_input::placeholder{
    color: rgba(0, 0, 0, 0.5);
}
.get_m_input:active{
    border: 1px solid #C9D5DA;
    box-sizing: border-box;
}
.get_m_input:focus{
    border: 1px solid #C9D5DA;
    box-sizing: border-box;
}
.get_m_btn{
    background: #F6DD00;
    box-shadow: 0px 4px 15px rgba(246, 221, 0, 0.4);
    border-radius: 4px;
    color: black;
    font-size: 14px;
    line-height: 19px;
    font-family: OpenSans-SemiBold;
    padding: 18px;
    width: 193px;
    margin-bottom: 47px;
    margin-top: 24px;
    text-align: center;
    
}
.center_get_m {
    position: relative;
    left: 50%;
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
}
.get_m_cancel{
    font-size: 14px;
    line-height: 19px;
    color: #000000;
    opacity: 0.5;
    text-align: center;
}