.MuiCheckbox-colorPrimary.Mui-checked{
    color : rgba(0, 134, 193, 0.9)
}
.MuiIconButton-colorPrimary {
    color : rgba(0, 134, 193, 0.9)
}
.MuiCheckbox-colorPrimary {
    color: black;
  }
  .MuiCheckbox-colorPrimary.Mui-selected { /* Increase the specificity */
    color:  rgba(0, 134, 193, 0.9)
  }